import Logo from "../assets/Wolverine-Logo-Orange.svg";

// import Facebook from "../assets/icons/Facebook.svg";
// import Twitter from "../assets/icons/Twitter.svg";
// import Instagram from "../assets/icons/Instagram.svg";
// import LinkedIn from "../assets/icons/LinkedIn.svg";
// import YouTube from "../assets/icons/YouTube.svg";

import { useMediaQuery } from "@mui/material"
import aiq from "../assets/Aiq_Logo.svg"
export default function SimpleFooter({content}) {
	let small = useMediaQuery("screen and (max-width: 800px)")
	let vsmall = useMediaQuery("screen and (max-width: 600px)")

	return <div style={{ background: "#242424", color: "white", fontSize: 18, padding: "40px 0", display: "flex", flexDirection: "column", gap: small ? 56 : 24 }}>
		<a href="https://www.arivu-iq.com/" style={{display: "flex", flexDirection: "column"}}>
		<img src={aiq} style={{ height: 100 }} /></a>
		<div style={{ textAlign: "center", padding: "0 20px" }}>
			<span>{content.copyright_text}</span>
			<span style={{ display: small ? "none" : "inline" }}> | </span>
			<br style={{ display: small ? "unset" : "none" }} />
			{
				content.copyright_links.map((obj,obj_i)=>(
					<>
						<a href={obj.link} style={{ color: "#E74E6E" }}>
							{obj.name}
						</a> 
						{content.copyright_links.length-1 != obj_i && (vsmall?<br/>:<> | </>)}
					</>
				))
			}
		</div>
	</div>
}