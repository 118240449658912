import "../styles/Index.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../components/Header";
import Product from "../components/index/Product";
// import PricingOption from "../components/index/PricingOption";
import FAQQuestion from "../components/index/FAQQuestion";

import robotics from "../assets/images/robotics.svg";
import clouds from "../assets/images/Cloud.svg";
import RightArrow from "../assets/icons/Arrow-Right.svg";

// import Google from "../assets/logos/Google.svg";
// import Facebook from "../assets/logos/Facebook.svg";
// import YouTube from "../assets/logos/YouTube.svg";
// import Pinterest from "../assets/logos/Pinterest.svg";
// import Twitch from "../assets/logos/Twitch.svg";
// import Webflow from "../assets/logos/Webflow.svg";

// import MacBook from "../assets/images/MacBook.svg";

// import Sec3Background from "../assets/images/background.svg";
// import SectionImage from "../components/index/SectionImage";
import Chip from "../components/index/Chip";
// import Step from "../components/index/Step";

// import Image1 from "../assets/index/image1.png";
// import Image2 from "../assets/index/image2.png";
// import Image3 from "../assets/index/image3.png";
// import Image4 from "../assets/index/image4.png";
// import Image5 from "../assets/index/image5.png";
// import Image6 from "../assets/index/image6.png";

import Email from "../assets/icons/Email.svg";
import Phone from "../assets/icons/Phone.svg";
import Mark from "../assets/icons/Mark.svg";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";

import Demo1 from "../assets/icons/design-ideas.svg";
import Demo2 from "../assets/icons/dimensional-view.svg";
import Demo3 from "../assets/icons/health-care-love.svg";

import Placeholder from "../assets/icons/Icon-Placeholder.svg";
import Placeholder2 from "../assets/icons/Image-Placeholder.svg";
import SimpleFooter from "../components/SimpleFooter";

export default function Index({content}) {
	const navigate = useNavigate();
	const [token, setToken] = useState(null);
	const FAQState = useState(0);

	// const baseUrl = "https://cms.istorylive.com"

	const contentData = content.attributes
	// useEffect(()=>{

	// 	console.log("---------",contentData,Object.keys(contentData).length)
	// 	contentData.demo_cards.map((i,e)=>{console.log(i,e)})
	// },[])
	// useEffect(() => {
	// 	const storedToken = localStorage.getItem('token');
    //     if (storedToken) {
	// 		setToken(storedToken);
    //     } 
	// 	console.log("inside useEffect",storedToken)
    //   }, []);
	// if (location.hash === "#contact-us") {
	// 	setTimeout(() => {
	// 		// document.getElementById("contact-us").scrollIntoView({ behavior: "smooth" });
	// 	}, 100);
	// }
	// useEffect(()=>{
    //     const hubspotScript = document.createElement('script');
    //     hubspotScript.type = "text/javascript"
    //     hubspotScript.id = "hs-script-loader";
    //     hubspotScript.src = "//js.hs-scripts.com/6040860.js";
    //     hubspotScript.async = true;
    //     hubspotScript.defer = true;
    //     document.body.appendChild(hubspotScript);
    // },[])

	return (
		<div>
			<Header></Header>
			
	<><div id="index">
	<div id="hero-image">
		<img src={robotics} alt="" />
	</div>
	<div id="hero">
		<div id="hero-text">
			<h1>{contentData.herobanner_text}</h1>
			<p>
			{contentData.herobanner_desc}
			</p>
		</div>
		{/* <div id="hero-buttons"> */}
			{/* <a onClick={() => navigate("/login")}>
				<button className="get-started orange-on-blue font">
					Get Started
					<img src={RightArrow} alt="" />
				</button>
			</a> */}
			{/* <button className="talk-to-sales">Talk to sales</button> */}
		{/* </div> */}
	</div>
	<div id="clouds" aria-label="An animation of clouds slowly flying to the left side of the screen." style={{ backgroundImage: "url(" + clouds + ")" }}>
	</div>
</div>
<div id="index-margin"></div></>

			
			
			{/*  */}
			{/* <div id="trusted-by-companies">
				Trusted by 10,000+ companies around the world
			</div>
			<div id="companies-using">
				<img src={Google} />
				<img src={Facebook} />
				<img src={YouTube} />
				<img src={Pinterest} />
				<img src={Twitch} />
				<img src={Webflow} />
			</div> */}
			{/* <div id="about-us">
				<div id="about-us-info">
					<Chip
						text="About us"
						col="var(--alt-color)"
						bg="#1E285F26"
					/>
					<h1>{contentData.about_us_Title}</h1>
					<p>
						{contentData.about_us_DescText}
					</p>
					{
						contentData.about_us_DescPoints.map((obj,obj_i)=>{
							return(

								<div>
									<img src={obj.img_url} />
									<span>{obj.content}</span>
								</div>
							)
						})
					}
					{/* <div>
						<img src={Placeholder} />
						<span>Lorem ipsum dolor sit amet</span>
					</div> }
				</div>
				<div id="about-us-image">
					<img src={baseUrl + contentData.about_us_Img.data[0].attributes.url} />
				</div>
			</div> */}
			{/* -------------------------------Try our Demo-------------------------------- */}
			{/* <div id="browse-our-products">
				<h1 className="linear_gradient">{contentData.demo_Title}</h1>
				<p>
					{contentData.demo_Desc}
				</p>
				<div id="products">
					{
						contentData.demo_cards.map((obj,obj_i)=>(
								<Product
									img={obj.img_url}
									alt={obj.img_alt}
									title={obj.title}
									description={obj.desc}
								/>
							)
						)
					}
					<Product
						img={Demo2}
						title="Visualization"
						description="Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalar cons elementum tempus hac."
					/>
					<Product
						img={Demo3}
						title="Health Care"
						description="Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalar cons elementum tempus hac."
					/>
				</div>
			</div> */}
			{/* <div id="sec-2">
				<div>
					<h2>
						Borem ipsum dolor sit amet, consectetur adipiscing elit.
					</h2>
					<p>
						Jorem ipsum dolor sit amet, consectetur adipiscing elit.
						Etiam eu turpis molestie, dictum est a, mattis tellus.
						Sed dignissim, metus nec fringilla accumsan, risus sem
						sollicitudin lacus, ut interdum tellus elit sed risus.
						Maecenas eget condimentum velit, sit amet feugiat
						lectus. Class aptent taciti
					</p>
					<p>
						sociosqu ad litora torquent per conubia nostra, per
						inceptos himenaeos. Praesent auctor purus luctus enim
						egestas, ac
					</p>
					<button>Talk to Sales</button>
				</div>
				<div>
					<img id="macbook" src={MacBook} />
				</div>
			</div> */}
			{/* <div id="sec-3">
				<div id="sec-3-background">
					<img src={Sec3Background}></img>
				</div>
				<div id="sec-3-images">
					<SectionImage
						color="var(--main-color)"
						title="Borem ipsum dolor sit amet, consectetur adipiscing elit."
						description="Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit phasellus mollis sit aliquam sit nullam."
					/>
					<SectionImage
						color="var(--red)"
						title="Borem ipsum dolor sit amet, consectetur adipiscing elit."
						description="Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit phasellus mollis sit aliquam sit nullam."
						reverse
					/>
					<SectionImage
						color="var(--green)"
						title="Borem ipsum dolor sit amet, consectetur adipiscing elit."
						description="Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit phasellus mollis sit aliquam sit nullam."
					/>
				</div>
			</div> */}
			{/* <div id="our-process">
				<div id="get-started-message">
					<div id="get-started-header">
						<Chip
							color="#FF5A5A"
							bg="#F28F8F26"
							text="Our Process"
						/>
						<h1>Get started as easy as 1, 2, 3</h1>
					</div>
					<p>
						Lorem ipsum dolor sit amet consectetur adipiscing elit
						tortor eu egestas morbi sem vulputate etiam facilisis
						pellentesque ut quis.
					</p>
				</div>
				<div className="steps">
					<Step
						heading="Yorem ipsum dolor"
						body="Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalar consectur elementum tempus hac."
						color="var(--red)"
					/>
					<Step
						heading="Yorem ipsum dolor"
						body="Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalar consectur elementum tempus hac."
						color="#39EDC2"
					/>
					<Step
						heading="Yorem ipsum dolor"
						body="Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalar consectur elementum tempus hac."
						color="#33336F"
					/>
				</div>
				<div className="steps">
					<Step
						heading="Yorem ipsum dolor"
						body="Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalar consectur elementum tempus hac."
						color="#2ED671"
					/>
					<Step
						heading="Yorem ipsum dolor"
						body="Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalar consectur elementum tempus hac."
						color="#FD6713"
					/>
					<Step
						heading="Yorem ipsum dolor"
						body="Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalar consectur elementum tempus hac."
						color="#654DFA"
					/>
				</div>
			</div> */}
			{/* <div id="images">
				<div id="images-header">
					<h1>
						Borem ipsum dolor sit amet, consectetur adipiscing elit.
					</h1>
					<p>
						Lorem ipsum dolor sit amet consectetur adipiscing elit
						semper dalar elementum tempus hac tellus libero
						accumsan.
					</p>
				</div>
				<div id="image-container">
					<div className="image-column">
						<img src={Image1} />
						<img src={Image2} />
					</div>
					<div className="image-column">
						<img src={Image3} />
						<img src={Image4} />
					</div>
					<div className="image-column">
						<img src={Image5} />
						<img src={Image6} />
					</div>
				</div>
			</div> */}
			{/* <div id="account-creation-prompt">
				<h1>{contentData.sec4_Title}</h1>
				<div id="account-creation-buttons">
					<button id="get-started-2" className="blue-on-orange">Get started</button>
					{/* <button id="talk-to-sales-2">Talk to sales</button> 
				</div>
			</div> */}
			{/* <div id="pricing">
				<div id="pricing-header">
					<div id="pricing-text">
						<Chip text="Pricing" color="#3DC55B" bg="#3DC55B26" />
						<h1>Affordable pricing plans</h1>
						<p>
							Lorem ipsum dolor sit amet consectetur adipiscing
							elit tortor eu egestas morbi sem vulputate etiam
							facilisis pellentesque ut quis.
						</p>
					</div>
					<div id="pricing-buttons">
						<button id="monthly-button">Monthly plan</button>
						<button id="yearly-button">Annual plan</button>
					</div>
				</div>
				<div id="pricing-options">
					<PricingOption
						name="Basic"
						price={99}
						body="Lorem ipsum dolor sit amet dolor siti conse ctetur adipiscing elit."
						items={[
							"Item A",
							"Item B",
							"Item C",
							"Item D",
							"Item E",
						]}
						color="var(--green)"
					/>
					<PricingOption
						popular
						name="Growth"
						price={199}
						body="Lorem ipsum dolor sit amet dolor siti conse ctetur adipiscing elit."
						items={[
							"Item F",
							"Item G",
							"Item H",
							"Item I",
							"Item J",
						]}
						color="var(--main-color)"
					/>
					<PricingOption
						name="Enterprise"
						price={399}
						body="Lorem ipsum dolor sit amet dolor siti conse ctetur adipiscing elit."
						items={[
							"Item K",
							"Item L",
							"Item M",
							"Item N",
							"Item O",
						]}
						color="var(--red)"
						textColor="white"
					/>
				</div>
			</div> */}
			<div id="faq">
				<div id="faq-header">
					<h1>{contentData.FAQ_Title}</h1>
					<p>
						{contentData.FAQ_Desc}
					</p>
				</div>
				{
					contentData.FAQ_Questions.map((obj,obj_i)=>(
						<FAQQuestion
							qid={obj_i}
							q={obj.question}
							a={obj.answer}
							state={FAQState}
						></FAQQuestion>
					))
				}
				{/* To anyone who encounters this code,
					and potentially finds an issue with FAQs being clipped off:
					Go to components/index/FAQQuestion.js, and increase
					the maxHeight of the <p> element. This wasn't a great solution.
					*/}
				{/* <FAQQuestion
					qid={0}
					q="What is Webflow and why is it the best website builder?"
					a="Lorem ipsum dolor sit amet, consectetur adipiscing elit id venenatis pretium risus euismod dictum egestas orci netus feugiat ut egestas ut sagittis tincidunt phasellus elit etiam cursus orci in. Id sed montes."
					state={FAQState}
				></FAQQuestion>
				<FAQQuestion
					qid={1}
					q="What is Webflow and why is it the best website builder?"
					a="Lorem ipsum dolor sit amet, consectetur adipiscing elit id venenatis pretium risus euismod dictum egestas orci netus feugiat ut egestas ut sagittis tincidunt phasellus elit etiam cursus orci in. Id sed montes."
					state={FAQState}
				></FAQQuestion>
				<FAQQuestion
					qid={2}
					q="What is Webflow and why is it the best website builder?"
					a="Lorem ipsum dolor sit amet, consectetur adipiscing elit id venenatis pretium risus euismod dictum egestas orci netus feugiat ut egestas ut sagittis tincidunt phasellus elit etiam cursus orci in. Id sed montes."
					state={FAQState}
				></FAQQuestion>
				<FAQQuestion
					qid={3}
					q="What is Webflow and why is it the best website builder?"
					a="Lorem ipsum dolor sit amet, consectetur adipiscing elit id venenatis pretium risus euismod dictum egestas orci netus feugiat ut egestas ut sagittis tincidunt phasellus elit etiam cursus orci in. Id sed montes."
					state={FAQState}
				></FAQQuestion> */}
			</div>
			{/* <div id="contact-us">
				<div id="contact-us-info">
					<Chip text="Contact us" color="#9747FF" bg="#654DFA26" />
					<h1>{contentData.Contact_Us_Title}</h1>
					<p>
						{contentData.Contact_Us_Desc}
					</p>
					<div id="contact-methods">
						{
							contentData.Contact_Us_Details.map((obj,obj_i)=>(
								<div>
									<img src={obj.img_url} />
									<span>{obj.content}</span>
								</div>
							))
						}
						{/* <div>
							<img src={Phone} />
							<span>(xxx) xxx-xxxx</span>
						</div>
						<div>
							<img src={Mark} />
							<span>xxx, xxxx xxxxxxx</span>
						</div> 
					</div>
				</div>
				<div id="contact-form">
					<div id="contact-inputs">
						<input type="text" aria-label="Name" placeholder="John Carter" />
						<input type="email" aria-label="Email" placeholder="example@email.com" />
						<input type="tel" aria-label="Telephone number" placeholder="(123) 456 - 7890" />
						<textarea placeholder="Please type your message here" />
					</div>
					<button className="white-on-blue">Send message</button>
				</div>
			</div> */}
			{
				contentData && Object.keys(contentData).length > 0 &&
				<>
					{/* <Newsletter content={contentData} /> */}
					<SimpleFooter content={contentData}  />
				</>
			}
		</div>
	);
}
