import Footer from "../components/Footer";
import Header from "../components/Header";
import SimpleFooter from "../components/SimpleFooter";
import "../styles/Terms.css";

export default function Privacy({ content }) {
	return (
		<div className="legal-container">
			<Header />
			<h1 className="legal-header">Privacy Policy</h1>
			<div className="legal-body">
				<p>
					This Privacy Policy describes how [Your Company Name] ("we,"
					"us," or "our") collects, uses, and discloses your personal
					information when you use our application (the
					"Application"). By accessing and using the Application, you
					consent to the terms and practices outlined in this Privacy
					Policy.
				</p>
				<ul className="legal-list">
					<li>
						<p>
							<b>Information We Collect</b>
							<ul>
								<li>
									Personal Information: We may collect
									personal information that you voluntarily
									provide to us, such as your name, email
									address, contact information, and any other
									information you choose to provide.
								</li>
								<li>
									Usage Data: We automatically collect certain
									information about your use of the
									Application, including your IP address,
									device information, operating system,
									browser type, and usage statistics.
								</li>
								<li>
									Cookies and Similar Technologies: We may use
									cookies and similar tracking technologies to
									enhance your experience and collect
									information about how you use the
									Application.
								</li>
							</ul>
						</p>
					</li>
					<li>
						<p>
							<b>Use of Information</b>
							<ul>
								<li>
									Provide and Improve the Application: We use
									the collected information to operate,
									maintain, and improve the functionality and
									performance of the Application.
								</li>
								<li>
									Communication: We may use your contact
									information to respond to your inquiries,
									provide customer support, and send you
									important updates and notifications related
									to the Application.
								</li>
								<li>
									Personalization: We may use the collected
									information to personalize your experience
									and provide tailored content and
									advertisements.
								</li>
								<li>
									Legal Compliance: We may use your
									information to comply with applicable laws,
									regulations, or legal processes, or to
									enforce our rights or protect our users'
									safety.
								</li>
							</ul>
						</p>
					</li>
					<li>
						<p>
							<b>Data Sharing and Disclosure</b>
							<ul>
								<li>
									Service Providers: We may share your
									information with third-party service
									providers who assist us in operating the
									Application, such as hosting providers,
									analytics providers, and payment processors.
									These providers are bound by confidentiality
									obligations and are prohibited from using
									your information for any other purpose.
								</li>
								<li>
									Legal Requirements: We may disclose your
									information if required by law, regulation,
									or legal process, or if we believe
									disclosure is necessary to protect our
									rights, the safety of our users, or to
									comply with a judicial proceeding, court
									order, or legal request.
								</li>
								<li>
									Business Transfers: In the event of a
									merger, acquisition, or sale of all or a
									portion of our assets, your information may
									be transferred as part of the transaction.
									We will notify you via email or prominent
									notice on the Application before your
									information becomes subject to a different
									privacy policy.
								</li>
							</ul>
						</p>
					</li>
					<li>
						<p>
							<b>Data Security:</b> We take reasonable measures to
							protect your personal information from unauthorized
							access, use, or disclosure. However, please note
							that no method of transmission over the Internet or
							electronic storage is 100% secure. We cannot
							guarantee the absolute security of your information.
						</p>
					</li>
					<li>
						<p>
							<b>Children's Privacy:</b> The Application is not
							intended for use by individuals under the age of 18.
							We do not knowingly collect personal information
							from children. If we become aware that we have
							inadvertently collected personal information from a
							child, we will take steps to delete it as soon as
							possible.
						</p>
					</li>
					<li>
						<p>
							<b>Third-Party Links and Services:</b> The
							Application may contain links to third-party
							websites, services, or advertisements. We are not
							responsible for the privacy practices or content of
							these third parties. We encourage you to review the
							privacy policies of those third parties before
							providing any personal information.
						</p>
					</li>
					<li>
						<p>
							<b>Your Choices:</b> You have the right to access,
							update, or delete your personal information. You may
							also opt-out of receiving promotional communications
							from us by following the instructions provided in
							the communication. Please note that certain
							communications are necessary for the operation of
							the Application, and you may not be able to opt out
							of them.
						</p>
					</li>
					<li>
						<p>
							<b>Changes to the Privacy Policy:</b> We reserve the
							right to modify or update this Privacy Policy at any
							time. Any changes will be effective immediately upon
							posting. We encourage you to review this Privacy
							Policy periodically for any updates.
						</p>
					</li>
					<li>
						<p>
							<b>Contact Us:</b> If you have any questions,
							concerns, or requests regarding this Privacy Policy
						</p>
					</li>
				</ul>
			</div>
			<SimpleFooter content={content.attributes} />
		</div>
	);
}
