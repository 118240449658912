import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';

const CustomToolbar = ({ title, handleOpen, handleSaveScene, previewiStoryBtn, handlePreviewiStory }) => {
  return (
    <AppBar sx={{backgroundColor:"#290936a8"}} position="static">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" sx={{ display: "flex", justifyContent: "center", flexGrow: 1 }}>
            {title}
            </Typography>
            <Button sx={{bgcolor:"#fdb813",color:"#33336f","&:hover":{bgcolor:"#fdb813",color:"#33336f"}, ml: "auto", mr:"8px" }} variant="contained" onClick={handleOpen}>
            Generate New
            </Button>
            {
              previewiStoryBtn ?
              <Button sx={{bgcolor:"#33336f",color:"#fdb813","&:hover":{bgcolor:"#33336f",color:"#fdb813"}, ml: "auto" }} variant="contained" onClick={handlePreviewiStory}>
            preview iStory
            </Button>
            :
            <Button sx={{bgcolor:"#33336f",color:"#fdb813","&:hover":{bgcolor:"#33336f",color:"#fdb813"}, ml: "auto" }} variant="contained" onClick={handleSaveScene}>
            Save
            </Button>
            }
        </Toolbar>
    </AppBar>
  );
};

export default CustomToolbar;