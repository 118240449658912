import { useMediaQuery } from "@mui/material";
import { useMemo } from "react";

export default function FAQQuestion({ qid, q, a, state }) {
	const [faq, setFaq] = state;
	let open = useMemo(() => faq === qid, [faq]);
	let small = useMediaQuery("screen and (max-width: 900px)");

	function onClick() {
		if (open) setFaq(-1);
		else setFaq(qid);
	}

	return (
		<div
			style={{ maxWidth: 890, width: "calc(100vw - 96px)", borderRadius: 25, border: "solid 1px #1E285F", padding: small ? 32 : "40px 60px", background: open ? "var(--alt-color)" : "none", transition: "0.4s all", color: open ? "var(--main-color)" : "#4D4D83", position: "relative" }}
			onClick={onClick}
		>
			{/* Inline SVGs are probably not good practice, but it's the easiest way to change the color on the fly */}
			<div style={{ cursor:"pointer", position: "absolute", top: 40, right: 40, transform: open ? "rotate(0)" : "rotate(-90deg)", transition: "0.4s all" }}>
				<svg width="37" height="40" viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g id="Line Rounded/Chevron Down">
						<g id="Chevron">
							<path id="Vector" d="M8 14.1533L18.5 25.3986L29 14.1533" stroke={ open ? "#E0E0E0" : "#4D4D83" } stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						</g>
					</g>
				</svg>
			</div>
			<h2
				style={{ userSelect: "none", margin: 0, fontSize: 28, marginBottom: 5, maxWidth: "90%" }}
			>
				{q}
			</h2>
			<p style={{ margin: 0, maxHeight: open ? 250 : 0, overflow: "hidden", transition: "0.4s all", color: "white", fontSize: 18, lineHeight: "30px", maxWidth: 720 }} onClick={e=>e.stopPropagation()}>{a}</p>
		</div>
	);
}
