import car1 from '../assets/project/cars/iStory_Img-3672d8a91b824a2dad5eac064b7ae551-xl.png'
import car2 from '../assets/project/cars/iStory_Img-68e09f700cca48249f178a33a23a070a-xl.png'
import car3 from '../assets/project/cars/iStory_Img-ba1448de3a0f4c769c53fb7ebb3f491f-xl.png'
import flower1 from '../assets/project/flower/iStory_Img-599b55ca45834432990e07f9a898a4a1-xl.png'
import flower2 from '../assets/project/flower/iStory_Img-f53f560651aa4b3e9069aee92f07822a-xl.png'
import flower3 from '../assets/project/flower/iStory_Img-d9cbca3dade34c4c8c605da0c017f78d-xl.png'
import tea1 from '../assets/project/tea/iStory_Img-34dba5bab5884de3a5b9f54cb1cf77b2-xl.png'
import tea2 from '../assets/project/tea/iStory_Img-e70b51ecf1a0461985cec80ec72399c8-xl.png'
import tea3 from '../assets/project/tea/iStory_Img-efc5f5e11db84a6599133f0176883230-xl.png'

export const data = [
    {
      title: "Introducing Porsche Cars",
      description: [
        "Porsche is more than just a brand of cars. It represents the pinnacle of automotive engineering, luxury, and performance. With a rich heritage and a commitment to excellence, Porsche continues to push the boundaries of what is possible in the world of automobiles.",
        "From iconic sports cars to luxurious sedans, each Porsche model is meticulously crafted with precision and attention to detail. With cutting-edge technology and unparalleled craftsmanship, our cars are designed to deliver an extraordinary driving experience."
      ],
      image_caption: "A sleek and powerful Porsche car standing out on the road, embodying the essence of automotive excellence.",
       cyoa_image_base64:car1
      },
    {
      title: "Experience Luxury and Performance",
      description: [
        "At Porsche, we believe that every drive should be an unforgettable experience. Our cars combine luxurious interiors with exhilarating performance to create a harmonious balance between comfort and excitement.",
        "Step inside a Porsche car and you will be greeted by premium materials, ergonomic design, and state-of-the-art features that elevate your driving experience. From the moment you start the engine, you will feel the power at your fingertips as you accelerate through breathtaking speeds."
      ],
      image_caption: "An interior shot of a Porsche car showcasing its opulent leather seats, advanced infotainment system, and meticulous attention to detail.",
      cyoa_image_base64:car2
    },
    {
      title: "Unleash Your Inner Speed Demon",
      description: [
        "For those who crave speed and adrenaline-fueled thrills, Porsche offers unrivaled performance that will ignite your passion for driving. Our sports cars are engineered for maximum power and agility.",
        "With exceptional handling capabilities and lightning-fast acceleration times, a drive in a Porsche car is like no other. Whether you're conquering winding mountain roads or tearing up the racetrack, our vehicles are designed to unleash your inner speed demon and deliver an exhilarating driving experience."
      ],
      image_caption: "A Porsche sports car in action, leaving a trail of dust and showcasing its dynamic design and unmatched performance.",
      cyoa_image_base64:car3
    }
  ]

  export const data1 =[
    {
      title: "Welcome to our Colorful Bouquet Shop",
      description: [
        "Welcome to our Colorful Bouquet Shop, where beauty and nature come together in a vibrant display of flowers. Step into our shop and be greeted by a kaleidoscope of colors, fragrances, and textures that will captivate your senses.",
        "We take pride in curating the finest selection of blooms from around the world, ensuring that each bouquet is a work of art that conveys your emotions and brightens any occasion."
      ],
      image_caption: "A colorful display of blooming flowers arranged in elegant vases, showcasing the enchanting beauty of our Colorful Bouquet Shop.",
      cyoa_image_base64:flower1
    },
    {
      title: "A Rainbow of Blooms",
      description: [
        "At our Colorful Bouquet Shop, we believe that flowers have the power to convey emotions like no other gift. From cheerful yellows to passionate reds, serene whites to vibrant purples, we offer a rainbow of blooms for every sentiment and celebration.",
        "Our expert florists handpick each bloom with care and arrange them into stunning bouquets that reflect your unique style and personality. Whether you're expressing love, gratitude, or sympathy, let our colorful blooms speak for you."
      ],
      image_caption: "A close-up shot capturing the vivid hues and delicate petals of an assortment of flowers at our Colorful Bouquet Shop.",
      cyoa_image_base64:flower2
    },
    {
      title: "Brighten Your Day with Our Bouquets",
      description: [
        "Looking for a way to brighten someone's day? Look no further than our Colorful Bouquet Shop. Our exquisite bouquets are designed to bring joy and happiness into people's lives.",
        "Whether it's surprising your loved ones on special occasions or simply showing someone you care, our bouquets are sure to make their day extra special. Each arrangement is carefully crafted with fresh flowers that radiate beauty and positivity."
      ],
      image_caption: "A smiling recipient holding a vibrant bouquet from our Colorful Bouquet Shop, their face lit up with delight and gratitude.",
      cyoa_image_base64:flower3
    }
  ]

  export const data2 = [
    {
      title: "The Classic Black Tea",
      description: [
        "Indulge in the rich and robust flavors of the classic black tea. Made from fully oxidized tea leaves, black tea offers a bold and satisfying taste that is perfect for starting your day or enjoying as an afternoon pick-me-up. With its deep amber color and invigorating aroma, black tea is a timeless favorite for tea enthusiasts.",
        "Whether you prefer it plain or with a splash of milk and sugar, the classic black tea is sure to awaken your senses and leave you feeling refreshed."
      ],
      image_caption: "A steaming cup of black tea with swirling steam rising from it, evoking warmth and comfort.",
      cyoa_image_base64:tea1
    },
    {
      title: "The Refreshing Green Tea",
      description: [
        "Experience the natural goodness of green tea. Known for its fresh and delicate flavor, green tea is made from unoxidized leaves that are carefully picked and immediately steamed or pan-fired to preserve their vibrant color and distinct taste. Savor the light grassy notes with subtle hints of sweetness as you sip on this refreshing beverage.",
        "In addition to its delightful taste, green tea is also renowned for its numerous health benefits. Packed with antioxidants, it can help boost metabolism, support weight loss efforts, improve brain function, lower the risk of chronic diseases, and promote overall well-being."
      ],
      image_caption: "A serene image capturing a cup of green tea surrounded by lush green leaves symbolizing freshness.",
      cyoa_image_base64:tea2
    },
    {
      title: "The Soothing Herbal Tea",
      description: [
        "Unwind with a comforting cup of herbal tea. Unlike traditional teas made from Camellia sinensis leaves (such as black or green), herbal teas are infusions made from various herbs, flowers, fruits, or spices. With their soothing properties and unique flavors, herbal teas offer a calming experience that can help you relax and unwind after a long day.",
        "From chamomile and lavender to peppermint and ginger, there is a wide variety of herbal teas to choose from. Each blend has its own distinct taste profile and potential health benefits, making herbal tea a versatile choice for any occasion."
      ],
      image_caption: "A soothing cup of herbal tea with delicate petals floating on the surface, creating an atmosphere of tranquility.",
      cyoa_image_base64:tea3
    }
  ]