import Footer from "../components/Footer";
import Header from "../components/Header";
import "../styles/Terms.css";

export default function Terms({ content }) {
	return (
		<div className="legal-container">
			<Header />
			<h1 className="legal-header">Terms of Use</h1>
			<div className="legal-body">
				<p>
					Please read these Terms and Conditions carefully before
					using our application (the "Application").
				</p>
				<ul className="legal-list">
					<li>
						<p>
							<b>Acceptance of Terms</b> By accessing and using
							the Application, you agree to be bound by these
							Terms and Conditions, which constitute a legally
							binding agreement between you and [Your Company
							Name] ("we," "us," or "our"). If you do not agree to
							these Terms and Conditions, you must refrain from
							using the Application.
						</p>
					</li>
					<li>
						<p>
							<b>Use of the Application</b> a. Eligibility: You
							must be at least 18 years old or the legal age of
							majority in your jurisdiction to use the
							Application. By using the Application, you represent
							and warrant that you meet these eligibility
							requirements. b. License: We grant you a limited,
							non-exclusive, non-transferable, and revocable
							license to use the Application for personal,
							non-commercial purposes, subject to these Terms and
							Conditions. c. Prohibited Activities: You agree not
							to engage in any of the following activities while
							using the Application:
							<ul>
								<li>
									Violating any applicable laws, regulations,
									or third-party rights.
								</li>
								<li>
									Modifying, adapting, translating,
									reverse-engineering, decompiling, or
									disassembling the Application.
								</li>
								<li>
									Impersonating another person or entity or
									providing false information.
								</li>
								<li>
									Transmitting any viruses, malware, or other
									harmful code.
								</li>
								<li>
									Interfering with or disrupting the operation
									or security of the Application.
								</li>
								<li>
									Collecting or harvesting any personally
									identifiable information from other users of
									the Application.
								</li>
							</ul>
						</p>
					</li>
					<li>
						<p>
							<b>User Accounts</b> a. Account Creation: In order
							to use certain features of the Application, you may
							need to create a user account. You are responsible
							for providing accurate and complete information
							during the registration process and maintaining the
							confidentiality of your account credentials. b.
							Account Security: You are solely responsible for all
							activities that occur under your account. You must
							notify us immediately of any unauthorized use or
							suspected security breach of your account. c.
							Termination: We reserve the right to terminate or
							suspend your account, at our sole discretion, if we
							believe you have violated these Terms and Conditions
							or engaged in any fraudulent, abusive, or unlawful
							activities.
						</p>
					</li>
					<li>
						<p>
							<b>Intellectual Property</b> a. Ownership: The
							Application, including all content, features, and
							functionality, is owned by us or our licensors and
							is protected by intellectual property laws. You
							acknowledge and agree that we retain all right,
							title, and interest in the Application. b.
							Restrictions: You may not copy, modify, distribute,
							transmit, display, perform, reproduce, publish,
							license, create derivative works from, transfer, or
							sell any part of the Application without our prior
							written consent.
						</p>
					</li>
					<li>
						<p>
							<b>Privacy</b> Your privacy is important to us.
							Please review our{" "}
							<a href="/privacy">Privacy Policy</a> to understand
							how we collect, use, and disclose your personal
							information in connection with the Application.
						</p>
					</li>
					<li>
						<p>
							<b>Disclaimer of Warranties</b> The Application is
							provided on an "as-is" and "as available" basis,
							without any warranties of any kind, whether express
							or implied. We do not guarantee that the Application
							will be uninterrupted, secure, or error-free.
						</p>
					</li>
					<li>
						<p>
							<b>Limitation of Liability</b> To the fullest extent
							permitted by applicable law, we shall not be liable
							for any direct, indirect, incidental, special,
							consequential, or exemplary damages arising out of
							or in connection with your use of the Application.
						</p>
					</li>
					<li>
						<p>
							<b>Modifications to the Terms and Conditions</b> We
							reserve the right to modify or update these Terms
							and Conditions at any time, without prior notice.
							Any changes will be effective immediately upon
							posting. Your continued use of the Application after
							the modifications constitute your acceptance of the
							revised Terms and Conditions.
						</p>
					</li>
					<li>
						<p>
							<b>Governing Law and Dispute Resolution</b> These
							Terms and Conditions shall be governed by and
							construed in accordance with the laws of
							[Jurisdiction]. Any disputes arising out of or in
						</p>
					</li>
				</ul>
			</div>
			<Footer content={content.attributes} />
		</div>
	);
}
