// import Facebook from "../assets/icons/Facebook.svg";
// import Twitter from "../assets/icons/Twitter.svg";
// import Instagram from "../assets/icons/Instagram.svg";
// import LinkedIn from "../assets/icons/LinkedIn.svg";
// import YouTube from "../assets/icons/YouTube.svg";

import { useMediaQuery } from "@mui/material"

export default function Footer({content}) {
	// Image and link pairs of social media links
	// const socials = [
	// 	[Facebook, "#"],
	// 	[Twitter, "#"],
	// 	[Instagram, "#"],
	// 	[LinkedIn, "#"],
	// 	[YouTube, "#"],
	// ]
	const socials = content.footer_socials

	const links = content.footer_sectionsLink

	let small = useMediaQuery("screen and (max-width: 800px)")

	return <div style={{ background: "#242424", color: "white", fontSize: 16, padding: small ? "40px 16px" : "64px 0" }}>
		<div style={{ display: "flex", justifyContent: "space-between", padding: small ? "0 0 20px 0" : "0px 120px 42px 120px", flexDirection: small ? "column" : "row", gap: small ? 16 : 0 }}>
			<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: 56, maxWidth: small ? "unset" : 340 }}>
				<p style={{ margin: 0, textAlign: small ? "center" : "left" }}>
					{content.footer_Desc}
				</p>
				{
					!small &&
					<div style={{ display: "flex", gap: 16 }}>
						{
							socials.map((obj,obj_i) => (
								<a href={obj.redirect_url} key={obj_i}>
									<img src={obj.img_url} />
								</a>
							))
						}
					</div>
				}
			</div>
			<div style={{ display: "flex", justifyContent: "space-between", maxWidth: 656, flexWrap: "wrap" }}>
				{Object.entries(links).map(([title, links]) => (
					<div style={{ minWidth: 150, marginBottom: small ? 20 : 0 }}>
						<h3 style={{ margin: 0, marginBottom: small ? 20 : 40 }}>{title}</h3>
						<div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
							{
								links.map(([title, link]) => (<a href={link} style={{ color: "white", textDecoration: "none" }}>
									{title}
								</a>))
							}
						</div>
					</div>
				))}
			</div>
		</div>
		{ !small && <hr style={{ border: "solid thin #ccc" }} /> }
		{
			small &&
			<div style={{ display: "flex", gap: 16, justifyContent: "center" }}>
				{
					socials.map((obj,obj_i) => (
						<a href={obj.redirect_url} key={obj_i}>
							<img src={obj.img_url} />
						</a>
					))
				}
			</div>
		}

		<p style={{ fontSize: 18, textAlign: "center", margin: 0, marginTop: 42 }}>
			{content.copyright_text}
			<span style={{ display: small ? "none" : "inline" }}> | </span>
			{
				content.copyright_links.map((obj,obj_i)=>(
					<>
						<a href={obj.link} style={{ color: 'rgb(231, 78, 110)' }}>
							{obj.name}
						</a> 
						{content.copyright_links.length-1 != obj_i && <> | </>}
					</>
				))
			}
			{/* <a href="#" style={{ color: "var(--main-color)" }}>Terms and Conditions</a> |
			<a href="#" style={{ color: "var(--main-color)" }}>Privacy Policy</a> */}
		</p>
	</div>
}