import "../styles/segmentation.css";
import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import { Box, Button, TextareaAutosize, TextField, Checkbox, Divider, Typography, Modal, CircularProgress, Tooltip, Grid, Card, CardContent, Stack, Menu, MenuItem, MenuList, ListItem, ListItemIcon, ListItemText, CardMedia, List, ListItemButton, Radio, InputBase, RadioGroup, FormControlLabel, FormGroup, LinearProgress, Input, InputAdornment, IconButton } from "@mui/material"

import Chart from 'chart.js/auto';
import { getRelativePosition } from 'chart.js/helpers';
import { Bar, Line, Pie, Doughnut, PolarArea, Radar, Scatter, Bubble } from 'react-chartjs-2';

import { VictoryBar, VictoryPie } from "victory";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import jwtDecode from "jwt-decode";
import uploadimg from "../assets/depositphotos_416951984-stock-illustration-vector-csv-file-icon-removebg-preview (2).png"
import { HoopSpinner } from "react-spinners-kit";
import { ArrowBack, Close, NearMe, UploadFile } from "@mui/icons-material";
import Analytics_svg from "../assets/Analytics.svg"
import Radar_svg from "../assets/Radar.svg"
import Privilege_svg from "../assets/Privilege.svg"
import Data_visualization_svg from "../assets/Data_visualization.svg"
import Idea_svg from "../assets/Idea.svg"
import Upload_svg from "../assets/Upload.svg"
import Leadership_svg from "../assets/Leadership.svg"
import Copy_svg from "../assets/Copy.svg"
import * as XLSX from 'xlsx';
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from 'primereact/multiselect';

const ChatMessage = React.memo(({ index, message }) => {
	const response = message.response;
	// console.log(response)
	// console.log("--------------ma-------------")
	const isDesktopLg = useMediaQuery({ query: '(min-width: 1536px)' });
	let data;
	let data1;
	let combinedData;
	const [isCombinedChartClicked, setIsCombinedChartClicked] = useState(false)
	const modal_style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 370,
		// bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
		backgroundColor: '#141313'
	};
	const colors = [
		'rgba(75, 192, 192, 0.8)', // Teal
		'rgba(255, 99, 132, 0.8)', // Red
		'rgba(54, 162, 235, 0.8)', // Blue
		'rgba(255, 159, 64, 0.8)', // Orange
		'rgba(153, 102, 255, 0.8)', // Purple
		'rgba(255, 205, 86, 0.8)', // Yellow
		'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
		'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
		'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
		'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
		'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
		'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
		'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
		'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
		'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
		'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
		'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
		'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
		'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
		'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
		'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
	]
	if (message.response.viz === "barChart" && message.response.stacked) {

		const datasets2 = Object.keys(message.response.dataValues1).map((key, index) => {
			const backgroundColor = index % 2 === 0 ? colors : colors;
			const borderColor = index % 2 === 0 ? colors : colors;

			return {
				label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
				data: message.response.dataValues1[key],
				backgroundColor: backgroundColor,
				borderColor: borderColor,
				borderWidth: 1
			};
		});

		data = {
			labels: message.response.labels,
			datasets: datasets2
		};

	}
	else if (message.response.viz === "doughnutChart" && message.response.stacked) {

		const datasets2 = Object.keys(message.response.dataValues1).map((key, index) => {
			const backgroundColor = index % 2 === 0 ? colors : colors;
			const borderColor = index % 2 === 0 ? colors : colors;

			return {
				label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
				data: message.response.dataValues1[key],
				backgroundColor: backgroundColor,
				borderColor: borderColor,
				borderWidth: 1
			};
		});

		data = {
			labels: message.response.labels,
			datasets: datasets2
		};

	}
	else if (message.response.viz === "pieChart" && message.response.stacked) {

		const datasets2 = Object.keys(message.response.dataValues1).map((key, index) => {
			const backgroundColor = index % 2 === 0 ? colors : colors;
			const borderColor = index % 2 === 0 ? colors : colors;

			return {
				label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
				data: message.response.dataValues1[key],
				backgroundColor: backgroundColor,
				borderColor: borderColor,
				borderWidth: 1
			};
		});

		data = {
			labels: message.response.labels,
			datasets: datasets2
		};

	}
	else if (message.response.viz === "lineChart" && message.response.stacked) {

		const datasets2 = Object.keys(message.response.dataValues1).map((key, index) => {
			const backgroundColor = index % 2 === 0 ? colors : colors;
			const borderColor = index % 2 === 0 ? colors : colors;

			return {
				label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
				data: message.response.dataValues1[key],
				backgroundColor: backgroundColor,
				borderColor: borderColor,
				borderWidth: 1
			};
		});

		data = {
			labels: message.response.labels,
			datasets: datasets2
		};

	}
	else if (message.response.viz === "polarareaChart" && message.response.stacked) {

		const datasets2 = Object.keys(message.response.dataValues1).map((key, index) => {
			const backgroundColor = index % 2 === 0 ? colors : colors;
			const borderColor = index % 2 === 0 ? colors : colors;

			return {
				label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
				data: message.response.dataValues1[key],
				backgroundColor: backgroundColor,
				borderColor: borderColor,
				borderWidth: 1
			};
		});

		data = {
			labels: message.response.labels,
			datasets: datasets2
		};

	}
	else if (message.response.viz === "radarChart") {
		data = {
			labels: Object.keys(message.response.dataValues[0]),
			datasets: [
				{
					label: message.response.labels[0],
					data: Object.values(message.response.dataValues[0]),
					backgroundColor: '#0EA5E960',
					borderColor: '#7DD3FC'

				}
			],
			backgroundColor: [
				'rgba(75, 192, 192, 0.8)', // Teal
				'rgba(255, 99, 132, 0.8)', // Red
				'rgba(54, 162, 235, 0.8)', // Blue
				'rgba(255, 159, 64, 0.8)', // Orange
				'rgba(153, 102, 255, 0.8)', // Purple
				'rgba(255, 205, 86, 0.8)', // Yellow
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
				'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
				'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
				'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
				'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
				'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
				'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
			],
			borderColor: [
				'rgba(75, 192, 192, 1)',
				'rgba(255, 99, 132, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(255, 159, 64, 1)',
				'rgba(153, 102, 255, 1)',
				'rgba(255, 205, 86, 1)',
				'rgba(75, 192, 192, 1)',
				'rgba(255, 99, 132, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(255, 159, 64, 1)',
				'rgba(153, 102, 255, 1)',
				'rgba(255, 205, 86, 1)',
				'rgba(75, 192, 192, 1)',
				'rgba(255, 99, 132, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(255, 159, 64, 1)',
				'rgba(153, 102, 255, 1)',
				'rgba(255, 205, 86, 1)',
				'rgba(75, 192, 192, 1)',
				'rgba(255, 99, 132, 1)',
			],
			borderWidth: 1,
		}

		// console.log(data)
		data1 = {
			labels: Object.keys(message.response.dataValues[0]),
			datasets: [
				{
					label: message.response.labels[1],
					data: message.response.dataValues[1] ? Object.values(message.response.dataValues[1]) : [],
					backgroundColor: '#EC489960',
					borderColor: '#F9A8D4',
				}

			],
			backgroundColor: [
				'rgba(255, 99, 132, 0.8)', // Red
				'rgba(54, 162, 235, 0.8)', // Blue
				'rgba(255, 159, 64, 0.8)', // Orange
				'rgba(153, 102, 255, 0.8)', // Purple
				'rgba(255, 205, 86, 0.8)', // Yellow
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
				'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
				'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
				'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
				'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
				'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
				'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
			],
			borderColor: [
				'rgba(255, 99, 132, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(255, 159, 64, 1)',
				'rgba(153, 102, 255, 1)',
				'rgba(255, 205, 86, 1)',
				'rgba(75, 192, 192, 1)',
				'rgba(255, 99, 132, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(255, 159, 64, 1)',
				'rgba(153, 102, 255, 1)',
				'rgba(255, 205, 86, 1)',
				'rgba(75, 192, 192, 1)',
				'rgba(255, 99, 132, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(255, 159, 64, 1)',
				'rgba(153, 102, 255, 1)',
				'rgba(255, 205, 86, 1)',
				'rgba(75, 192, 192, 1)',
				'rgba(255, 99, 132, 1)',
			],
			borderWidth: 2
		}
		// console.log(data1)
		combinedData = {
			labels: Object.keys(message.response.dataValues[0]),
			datasets: [
				{
					label: message.response.labels[0],
					data: Object.values(message.response.dataValues[0]),
					backgroundColor: '#0EA5E960',
					borderColor: '#7DD3FC'

				},
				{
					label: message.response.labels[1],
					data: message.response.dataValues[1] ? Object.values(message.response.dataValues[1]) : [],
					backgroundColor: '#EC489960',
					borderColor: '#F9A8D4',

				}
			],
			backgroundColor: [
				'rgba(75, 192, 192, 0.8)', // Teal
				'rgba(255, 99, 132, 0.8)', // Red
				'rgba(54, 162, 235, 0.8)', // Blue
				'rgba(255, 159, 64, 0.8)', // Orange
				'rgba(153, 102, 255, 0.8)', // Purple
				'rgba(255, 205, 86, 0.8)', // Yellow
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
				'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
				'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
				'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
				'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
				'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
				'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
			],
			borderColor: [
				'rgba(75, 192, 192, 1)',
				'rgba(255, 99, 132, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(255, 159, 64, 1)',
				'rgba(153, 102, 255, 1)',
				'rgba(255, 205, 86, 1)',
				'rgba(75, 192, 192, 1)',
				'rgba(255, 99, 132, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(255, 159, 64, 1)',
				'rgba(153, 102, 255, 1)',
				'rgba(255, 205, 86, 1)',
				'rgba(75, 192, 192, 1)',
				'rgba(255, 99, 132, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(255, 159, 64, 1)',
				'rgba(153, 102, 255, 1)',
				'rgba(255, 205, 86, 1)',
				'rgba(75, 192, 192, 1)',
				'rgba(255, 99, 132, 1)',
			],
			borderWidth: 1,
		}
		// console.log("---------cd-------",combinedData)


	}
	else {
		data = {
			// labels: ['My First Dataset', 'My second Dataset', 'My third Dataset', 'My Four Dataset', 'My five Dataset'],
			labels: message.response.labels,
			datasets: [{
				//   label: message.labels,
				data: message.response.dataValues,
				//   data: [59, 81, 56, 55, 40],
				backgroundColor: [
					'rgba(75, 192, 192, 0.8)', // Teal
					'rgba(255, 99, 132, 0.8)', // Red
					'rgba(54, 162, 235, 0.8)', // Blue
					'rgba(255, 159, 64, 0.8)', // Orange
					'rgba(153, 102, 255, 0.8)', // Purple
					'rgba(255, 205, 86, 0.8)', // Yellow
					'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
					'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
					'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
					'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
					'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
					'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
					'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
					'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
					'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
					'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
					'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
					'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
					'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
					'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
					'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
				],
				borderColor: [
					'rgba(75, 192, 192, 1)',
					'rgba(255, 99, 132, 1)',
					'rgba(54, 162, 235, 1)',
					'rgba(255, 159, 64, 1)',
					'rgba(153, 102, 255, 1)',
					'rgba(255, 205, 86, 1)',
					'rgba(75, 192, 192, 1)',
					'rgba(255, 99, 132, 1)',
					'rgba(54, 162, 235, 1)',
					'rgba(255, 159, 64, 1)',
					'rgba(153, 102, 255, 1)',
					'rgba(255, 205, 86, 1)',
					'rgba(75, 192, 192, 1)',
					'rgba(255, 99, 132, 1)',
					'rgba(54, 162, 235, 1)',
					'rgba(255, 159, 64, 1)',
					'rgba(153, 102, 255, 1)',
					'rgba(255, 205, 86, 1)',
					'rgba(75, 192, 192, 1)',
					'rgba(255, 99, 132, 1)',
				],
				borderWidth: 1
			}]
		};
	}

	// console.log("================================", datasets2)
	const chartoption = {
		aspectRatio: 2,
		scales: {
			x: {
				ticks: {
					color: 'white', // Change this to the desired color
					display: message.response.viz === "pieChart" || message.response.viz === "doughnutChart" || message.response.viz === "polarareaChart" ? false : true

				},
			},
			y: {
				ticks: {
					color: 'white', // Change this to the desired color
					display: message.response.viz === "pieChart" || message.response.viz === "doughnutChart" || message.response.viz === "polarareaChart" ? false : true

				},
			},
		},
		onClick: (event, elements) => {
			if (elements.length > 0) {
				const datasetIndex = elements[0].datasetIndex;
				const dataIndex = elements[0].index;
				const dataValue = event.chart.data.datasets[datasetIndex].data[dataIndex];
				// console.log(`Clicked on datasetIndex: ${datasetIndex}, dataIndex: ${dataIndex}, dataValue: ${dataValue}`);
			}
		},
		responsive: true,
		maintainAspectRatio: true,
		plugins: {
			legend: {
				onClick: (e, legendItem, legend) => {
					const chart = legend.chart;
					const datasetIndex = chart.data.labels.indexOf(legendItem.text)
					chart.toggleDataVisibility(datasetIndex)
					chart.update();
				},
				display: true,
				position: 'right',
				labels: {
					generateLabels: (chart) => {
						let visibility = []
						for (let i = 0; i < chart.data.labels.length; i++) {
							if (chart.getDataVisibility(i) === true) {
								visibility.push(false)
							} else {
								visibility.push(true)
							}
						}
						const originalLabels = chart.data.labels;
						const originalColors = chart.data.datasets[0].backgroundColor;
						const labels = [];

						originalLabels.forEach((label, index) => {
							labels.push({
								text: label,
								fontColor: 'white',
								fillStyle: originalColors[index],
								hidden: visibility[index]
							});
						});

						return labels;
					},
				},
			},
			title: {
				display: true,
				text: 'Visualization',
				color: 'white',
			},
		},
	};

	if (Object.keys(response).includes("viz")) {

		var Radarchartoption1 = {
			aspectRatio: 1,
			scales: {
				x: {
					display: message.response.viz === "radarChart" ? false : true,
					ticks: {
						color: 'white', // Change this to the desired color
						display: message.response.viz === "pieChart" || message.response.viz === "doughnutChart" || message.response.viz === "polarareaChart" || message.response.viz === "radarChart" ? false : true

					},
					grid: {
						display: message.response.viz === "radarChart" ? false : true
					}
				},
				y: {
					display: message.response.viz === "radarChart" ? false : true,
					ticks: {
						color: 'white', // Change this to the desired color
						display: message.response.viz === "pieChart" || message.response.viz === "doughnutChart" || message.response.viz === "polarareaChart" || message.response.viz === "radarChart" ? false : true

					},
					grid: {
						display: message.response.viz === "radarChart" ? false : true
					}
				},
				r: {
					grid: {
						color: "#BDBDBD"
					},
					angleLines: {
						display: true,
						color: '#BDBDBD'
					},
					ticks: {
						color: '#BDBDBD',
						backdropPadding: 5,
						backdropColor: "#343541",
						// stepSize : 10,
					},
					pointLabels: {
						color: "#BDBDBD"
					}
				}
			},
			onClick: (event, elements) => {
				if (elements.length > 0) {
					const datasetIndex = elements[0].datasetIndex;
					const dataIndex = elements[0].index;
					const dataValue = event.chart.data.datasets[datasetIndex].data[dataIndex];
					// console.log(`Clicked on datasetIndex: ${datasetIndex}, dataIndex: ${dataIndex}, dataValue: ${dataValue}`);
				}
			},
			responsive: true,
			maintainAspectRatio: true,
			plugins: {
				legend: {
					//   onClick: (e, legendItem, legend) => {
					// 	console.log("----coming---",legendItem)
					// 	  const chart = legend.chart;
					// 	  const datasetIndex = chart.data.datasets.map((e)=>{return e.label}).indexOf(legendItem.text)
					// 	  chart.toggleDataVisibility(datasetIndex)
					// 	  chart.update(); 
					// 	},
					display: message.response.viz === "radarChart" && !isCombinedChartClicked ? false : true,
					position: 'bottom',
					labels: {
						color: "#FFFFFF",
						font: {
							family: "Poppins",
							size: "14px",
							style: "normal",
							weight: 600
						}
						// 	  generateLabels: (chart) => {
						// 		  let visibility = []
						// 		  for(let i=0;i<chart.data.labels.length;i++){
						// 			  if(chart.getDataVisibility(i)===true){
						// 				  visibility.push(false)
						// 			  }else{
						// 				  visibility.push(true)
						// 			  }
						// 		  }
						// 		const originalLabels = chart.data.labels;
						// 		const originalColors = chart.data.datasets[0].backgroundColor;
						// 		const labels = [];

						// 		originalLabels.forEach((label, index) => {
						// 		  labels.push({
						// 			text: label,
						// 			fontColor: 'white',
						// 			fillStyle: originalColors[index],
						// 			hidden:visibility[index]
						// 		  });
						// 		});

						// 		return labels;
						// 	  },
					},
				},
				title: {
					display: false,
					text: message.response.labels[0] == 1 ? "Westside" : "Max",
					color: 'white',
				},
			},
		};
		var Radarchartoption2 = {
			aspectRatio: 1,
			scales: {
				x: {
					display: message.response.viz === "radarChart" ? false : true,
					ticks: {
						color: 'white', // Change this to the desired color
						display: message.response.viz === "pieChart" || message.response.viz === "doughnutChart" || message.response.viz === "polarareaChart" || message.response.viz === "radarChart" ? false : true

					},
					grid: {
						display: message.response.viz === "radarChart" ? false : true
					}
				},
				y: {
					display: message.response.viz === "radarChart" ? false : true,
					ticks: {
						color: 'white', // Change this to the desired color
						display: message.response.viz === "pieChart" || message.response.viz === "doughnutChart" || message.response.viz === "polarareaChart" || message.response.viz === "radarChart" ? false : true

					},
					grid: {
						display: message.response.viz === "radarChart" ? false : true
					}
				},
				r: {
					// suggestedMax:100,
					grid: {
						color: "#BDBDBD"
					},
					angleLines: {
						display: true,
						color: '#BDBDBD'
					},
					ticks: {
						color: '#BDBDBD',
						backdropPadding: 5,
						backdropColor: "#343541",
						// stepSize : 30,
					},
					pointLabels: {
						color: "#BDBDBD"
					}
				}
			},
			onClick: (event, elements) => {
				if (elements.length > 0) {
					const datasetIndex = elements[0].datasetIndex;
					const dataIndex = elements[0].index;
					const dataValue = event.chart.data.datasets[datasetIndex].data[dataIndex];
					// console.log(`Clicked on datasetIndex: ${datasetIndex}, dataIndex: ${dataIndex}, dataValue: ${dataValue}`);
				}
			},
			responsive: true,
			maintainAspectRatio: true,
			plugins: {
				legend: {
					onClick: (e, legendItem, legend) => {
						const chart = legend.chart;
						const datasetIndex = chart.data.labels.indexOf(legendItem.text)
						chart.toggleDataVisibility(datasetIndex)
						chart.update();
					},
					display: message.response.viz === "radarChart" ? false : true,
					position: 'right',
					//   labels: {		
					// 	  generateLabels: (chart) => {
					// 		  let visibility = []
					// 		  for(let i=0;i<chart.data.labels.length;i++){
					// 			  if(chart.getDataVisibility(i)===true){
					// 				  visibility.push(false)
					// 			  }else{
					// 				  visibility.push(true)
					// 			  }
					// 		  }
					// 		const originalLabels = chart.data.labels;
					// 		const originalColors = chart.data.datasets[0].backgroundColor;
					// 		const labels = [];

					// 		originalLabels.forEach((label, index) => {
					// 		  labels.push({
					// 			text: label,
					// 			fontColor: 'white',
					// 			fillStyle: originalColors[index],
					// 			hidden:visibility[index]
					// 		  });
					// 		});

					// 		return labels;
					// 	  },
					// 	},
				},
				title: {
					display: false,
					text: message.response.labels[1] == 12 ? "Fabindia" : null,
					color: 'white',
				},
			},
		};
		var CombinedRadarchartoption1 = {
			aspectRatio: 1,
			scales: {
				x: {
					display: message.response.viz === "radarChart" ? false : true,
					ticks: {
						color: 'white', // Change this to the desired color
						display: message.response.viz === "pieChart" || message.response.viz === "doughnutChart" || message.response.viz === "polarareaChart" || message.response.viz === "radarChart" ? false : true

					},
					grid: {
						display: message.response.viz === "radarChart" ? false : true
					}
				},
				y: {
					display: message.response.viz === "radarChart" ? false : true,
					ticks: {
						color: 'white', // Change this to the desired color
						display: message.response.viz === "pieChart" || message.response.viz === "doughnutChart" || message.response.viz === "polarareaChart" || message.response.viz === "radarChart" ? false : true

					},
					grid: {
						display: message.response.viz === "radarChart" ? false : true
					}
				},
				r: {
					grid: {
						color: "#BDBDBD"
					},
					angleLines: {
						display: true,
						color: '#BDBDBD'
					},
					ticks: {
						color: '#BDBDBD',
						backdropPadding: 5,
						backdropColor: "#374151",
						// stepSize : 10,
					},
					pointLabels: {
						color: "#BDBDBD"
					}
				}
			},
			onClick: (event, elements) => {
				if (elements.length > 0) {
					const datasetIndex = elements[0].datasetIndex;
					const dataIndex = elements[0].index;
					const dataValue = event.chart.data.datasets[datasetIndex].data[dataIndex];
					// console.log(`Clicked on datasetIndex: ${datasetIndex}, dataIndex: ${dataIndex}, dataValue: ${dataValue}`);
				}
			},
			responsive: true,
			maintainAspectRatio: true,
			plugins: {
				legend: {
					//   onClick: (e, legendItem, legend) => {
					// 	console.log("----coming---",legendItem)
					// 	  const chart = legend.chart;
					// 	  const datasetIndex = chart.data.datasets.map((e)=>{return e.label}).indexOf(legendItem.text)
					// 	  chart.toggleDataVisibility(datasetIndex)
					// 	  chart.update(); 
					// 	},
					display: message.response.viz === "radarChart" && !isCombinedChartClicked ? false : true,
					position: 'bottom',
					labels: {
						color: "#FFFFFF",
						font: {
							family: "Poppins",
							size: "14px",
							style: "normal",
							weight: 600
						}
						// 	  generateLabels: (chart) => {
						// 		  let visibility = []
						// 		  for(let i=0;i<chart.data.labels.length;i++){
						// 			  if(chart.getDataVisibility(i)===true){
						// 				  visibility.push(false)
						// 			  }else{
						// 				  visibility.push(true)
						// 			  }
						// 		  }
						// 		const originalLabels = chart.data.labels;
						// 		const originalColors = chart.data.datasets[0].backgroundColor;
						// 		const labels = [];

						// 		originalLabels.forEach((label, index) => {
						// 		  labels.push({
						// 			text: label,
						// 			fontColor: 'white',
						// 			fillStyle: originalColors[index],
						// 			hidden:visibility[index]
						// 		  });
						// 		});

						// 		return labels;
						// 	  },
					},
				},
				title: {
					display: false,
					text: message.response.labels[0] == 1 ? "Westside" : "Max",
					color: 'white',
				},
			},
		};
	}
	// console.log(Radarchartoption1)
	// }

	// console.log("---------",isCombinedChartClicked)

	const value = "section_" + index

	//////////////////////////////////////////////////////////////// REFERENECE FOR STACKED BAR CHARTS //////////////////////////////////////////////////////////////////
	//   const chartData1 = {
	// 	labels: ["India", "China", "Malaysia"],
	// 	datasets: [
	// 	  {
	// 		label: 'Male',
	// 		backgroundColor: 'rgba(54, 162, 235, 0.6)', // Blue
	// 		borderColor: 'rgb(54, 162, 235)',
	// 		borderWidth: 1,
	// 		data: [200, 180, 150]
	// 	  },
	// 	  {
	// 		label: 'Female',
	// 		backgroundColor: 'rgba(255, 99, 132, 0.6)', // Pink
	// 		borderColor: 'rgb(255, 99, 132)',
	// 		borderWidth: 1,
	// 		data: [132, 120, 110]
	// 	  }
	// 	]
	//   };

	//   const chartoption1 = {
	// 	scales: {
	// 	  x: { stacked: true },
	// 	  y: { stacked: true }
	// 	}
	//   };

	return (
		<Grid id={value} container p={"24px"}>
			<Grid sx={{ borderBottom: 0 }} item xs={12}>

				<Box>
					<h1 style={{ color: "#11dd2a", margin: 0 }}>
						{message.message}
					</h1>
					{
						Object.keys(response).includes("viz") ? <FormControlLabel sx={{ my: "12px" }} control={<Checkbox sx={{ color: "#FFF", "&.Mui-checked": { color: "#EC4899" } }} checked={isCombinedChartClicked} onClick={() => setIsCombinedChartClicked(!isCombinedChartClicked)} />} label="Show Combined Chart" />
						:
						<h3>
							{message.response.message}
						</h3>
					}
				</Box>

				<Box>
					{message.response.viz === "pieChart" && (
						<>
							<Box className="chart-container" sx={{ width: "800px", height: "400px" }}>
								<Pie data={data} options={chartoption} />
							</Box>
						</>
					)}
					{/* ////////////REFERENCE FOR STACKED BAR CHARTS//////////// */}
					{/* {message.response.viz === "barChart" && (
				<>
					<Box className="chart-container" sx={{width:"800px",textAlign:"-webkit-center"}} >
						<Bar data={chartData1} option={chartoption1} />
					</Box>
				</>
			)} */}
					{message.response.viz === "barChart" && (
						<>
							<Box className="chart-container" sx={{ width: "800px", height: "400px" }} >
								<Bar data={data} options={chartoption} />
							</Box>
						</>
					)}
					{message.response.viz === "doughnutChart" && (
						<>
							<Box className="chart-container" sx={{ width: "1000px", height: "400px" }} >
								<Doughnut data={data} options={chartoption} />
							</Box>
						</>
					)}
					{message.response.viz === "lineChart" && (
						<>
							<Box className="chart-container" sx={{ width: "800px", height: "400px" }} >
								<Line data={data} options={chartoption} />
							</Box>
						</>
					)}
					{message.response.viz === "polarareaChart" && (
						<>
							<Box className="chart-container" sx={{ width: "800px", textAlign: "-webkit-center" }} >
								<PolarArea data={data} options={chartoption} />
							</Box>
						</>
					)}
					{message.response.viz === "radarChart" && (
						<>
							<Grid container sx={{gap:"36px", border: "1px solid #64748B", justifyContent: isDesktopLg ? "space-around" : "center", p: "24px", height:isDesktopLg?"530px":"unset", textAlign: isDesktopLg ? "unset" : "center" }}>
								<Grid item sx={{ }}>
									<Box sx={{ mb: "19px", fontFamily: "Poppins", fontSize: "20px", fontWeight: 700, lineHeight: "28px" }}>
										<Typography>{message.response.labels[0]}</Typography>
									</Box>
									<Box className="chart-container" sx={{ width: "450px", textAlign: "-webkit-center" }} >
										<Radar
											data={data} options={Radarchartoption1} />
									</Box>
								</Grid>
								{
									isDesktopLg ? <Divider orientation="vertical" sx={{ borderWidth: "1px", bgcolor: "#64748B", height: "100%" }} />
										:
										<Divider sx={{ borderWidth: "1px", bgcolor: "#64748B", width: "100%", mb: "24px" }} />
								}
								<Grid item sx={{  }}>
									<Box sx={{ mb: "19px", fontFamily: "Poppins,sans-serif !important", fontSize: "20px", fontWeight: 700, lineHeight: "28px" }}>
										<Typography>{message.response.labels[1]}</Typography>
									</Box>
									<Box className="chart-container" sx={{ width: "450px", textAlign: "-webkit-center" }} >
										<Radar
											data={data1} options={Radarchartoption2} />
									</Box>
								</Grid>
							</Grid>
						</>
					)}
					{message.response.viz === "scatterChart" && (
						<>
							<Box className="chart-container" sx={{ width: isDesktopLg ? "500px" : "300px", textAlign: "-webkit-center" }} >
								<Scatter data={data} options={chartoption} />
							</Box>
						</>
					)}
					{message.response.viz === "bubbleChart" && (
						<>
							<Box className="chart-container" sx={{ width: isDesktopLg ? "500px" : "300px", textAlign: "-webkit-center" }} >
								<Bubble data={data} options={chartoption} />
							</Box>
						</>
					)}
				</Box>
			</Grid>

			{Object.keys(response).includes("viz")?<Modal
				open={isCombinedChartClicked}
				// closeAfterTransition
				slots={{ backdrop: null }}
				onClose={() => setIsCombinedChartClicked(false)}
				sx={{ bgcolor: "#37415180", left: "292px", top: "88px" }}
				hideBackdrop
			>
				<Box>
					<Stack sx={{ alignItems: "end", pr: "36px", pt: "24px", cursor: "pointer" }}>
						<Close sx={{ color: "#FFFFFF" }} onClick={() => setIsCombinedChartClicked(false)} />
					</Stack>
					<Stack sx={{ ...modal_style, width: isDesktopLg ? "700px" : "460px", backgroundColor: "#374151" }}>
						<Typography sx={{ color: "#FFFFFF", fontFamily: "Poppins", fontSize: "20px", fontWeight: 700, lineHeight: "28px" }}>{message.response.labels[0]} & {message.response.labels[1]}</Typography>
						{/* <Box className="chart-container" sx={{textAlign:"-webkit-center"}} > */}
						<Radar data={combinedData} options={CombinedRadarchartoption1} />
						{/* </Box> */}
					</Stack>
				</Box>
			</Modal>:null}

		</Grid>
	);
});

export default function Segmentation() {
	// const API_BASE = "http://127.0.0.1:8000/segmentation-agents/"
	const API_BASE = "https://drf-il-test.arivu-iq.com/segmentation-agents/"
	const [question, setQuestion] = useState("");
	const { REACT_APP_NLU_IL_URL } = process.env;
	const [chatLog, setChatLog] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);
	const [questionView, setQuestionView] = useState("DA");
	const [fileType, setFileType] = useState(null);
	const [filename, setfileName] = useState(null);
	const [isLoading, setLoading] = useState(false);
	const [history, setHistory] = useState([])
	const messagesEndRef = useRef(null)
	const [api_usage, setApi_usage] = useState(0)
	// const [restrictionOpen,setRestrictionOpen] = useState(false)
	const [CombinedChartModalopen, setCombinedChartModalopen] = useState(false)
	const isMobile = useMediaQuery({ maxWidth: 600 }); // Adjust the breakpoint as needed
	const isTablet = useMediaQuery({ query: '(min-width: 600px) and (max-width: 900px)' });
	const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });
	const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });
	const isDesktopLg = useMediaQuery({ query: '(min-width: 1536px)' });
	const [isRadioBtnClicked, setIsRadioBtnClicked] = useState("DA")
	const [isSendBtnClicked, setIsSendBtnClicked] = useState(false)
	const [uploadedFile, setUploadedFile] = useState('')
	const fileRef = useRef()
	const [isChartWindowOpened, setIsChartWindowOpened] = useState(false)
	const [uploadProgress, setUploadProgress] = useState(0);
	const [brandColValue, setBrandcolValue] = useState([])
	const [CatColValue, setCatColValue] = useState([])
	const [selectedBrandColValue, setSelectedBrandcolValue] = useState(null)
	const [selectedCatColValue, setSelectedCatColValue] = useState(null)

	const modal_style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 370,
		// bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
		backgroundColor: '#141313'
	};

	const navigate = useNavigate();
	const bottomRef = useRef(null);
	const victoryColors = [
		"#d66",
		"#d96",
		"#dd6",
		"#9d6",
		"#6d6",
		"#6d9",
		"#6dd",
		"#69d",
		"#66d",
		"#96d",
		"#d6d",
		"#d69",
	];

	const [isMAChecked, setIsMAChecked] = useState(false)

	useEffect(() => {
		// 👇️ scroll to bottom every time messages change
		bottomRef.current?.scrollIntoView({ behavior: "smooth" });
	}, [chatLog]);


	const handleFileChange = event => {
		setQuestionView("DA");
		openFileDialog()
	};

	

	const formatColumnArr = (data) => {
		let dummy_obj = []
		data.map((val) => dummy_obj.push({ name: val, code: val }))
		setBrandcolValue(dummy_obj)
		setCatColValue(dummy_obj)
	}


	const GetCol_names = (file) => {
		// setUploadProgress(10)
		const reader = new FileReader();

		reader.onload = (evt) => {
			const bstr = evt.target.result;
			const wb = XLSX.read(bstr, { type: 'binary' });
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
			// Assuming the first row contains column names
			// setUploadProgress(40)
			if (data.length > 0) {
				// setUploadProgress(80)
				// setDropDownValues(data[0]);
				console.log(data[0])
				formatColumnArr(data[0])
			}
			// setUploadProgress(100)
		};
		reader.readAsBinaryString(file);
	}


	const handleManualFileUpload = (e) => {
		let file = e.target.files[0]
		if (file.name.endsWith('.xlsx')) {
			// if (usertier=="paid plan"&&e.target.files[0].size > 150 * 1024 * 1024){
			// setIsCsvSelected(false)
			// setFileErr("File size is restricted to 150MB")
			// setMoberr('File size is restricted to 150MB')
			// }
			// else if (usertier=="free plan"&&e.target.files[0].size > 5 * 1024 * 1024){
			// setIsCsvSelected(false)
			// setFileErr("File size is restricted to 5MB")
			// setMoberr('File size is restricted to 5MB')
			// }
			// else{
			GetCol_names(file)
			setUploadedFile(file)
			// setIsCsvSelected(true)
			// setFileErr('')
			// setMoberr('')
			// }
		}
	}


	const openFileDialog = async () => {
		// const api_usage_response = await axios.post(API_BASE + 'api_usage/',{"user_id":jwtDecode(storedToken).user_id,"type":"dna"})
		// if((api_usage_response.status === 200 && api_usage_response.data.count >= 0 )|| api_usage_response.data.user==7){

		setQuestionView("DA");
		setChatLog([]);
		// 	// document.getElementById("dna-input").click();
		// }
		// else if (api_usage_response.status === 200 && api_usage_response.data.count<0){
		// 	// setRestrictionOpen(true)
		// 	setLoading(false);
		// }
		// else{
		// 	setLoading(false);
		// }
	};


	function handleSubmit(event) {
		setLoading(true)
		// console.log("---------pd----------", selectedBrandColValue.name, selectedCatColValue.map(obj => { return obj.name }).join(","))
		event.preventDefault(); // 👈️ prevent page refresh
		// let cdata = {
		// 	1: "9.63%",
		// 	12: "3.03%",
		// 	profile_data: {
		// 		"centre": {
		// 			"128": 1,
		// 			"543": 6,
		// 			"583": 7,
		// 			"705": 12,
		// 			"817": 1,
		// 			"966": 9,
		// 			"1018": 3,
		// 			"1337": 12
		// 		},
		// 		"RQ51": {
		// 			"2": 21,
		// 			"3": 15,
		// 			"4": 6,
		// 			"5": 9
		// 		},
		// 		"RQ6": {
		// 			"1": 20,
		// 			"2": 31
		// 		},
		// 		"RQ10": {
		// 			"1": 7,
		// 			"2": 22,
		// 			"3": 11,
		// 			"4": 8,
		// 			"5": 2,
		// 			"6": 1
		// 		}
		// 	},
		// 	column_counts: {
		// 		"1": {
		// 			"RQ11_1": 75,
		// 			"RQ11_2": 14,
		// 			"RQ11_3": 96,
		// 			"RQ11_4": 80,
		// 			"RQ11_5": 123,
		// 			"RQ11_6": 94,
		// 			"RQ11_7": 77,
		// 			"RQ11_8": 135,
		// 			"RQ11_9": 138,
		// 			"RQ11_11": 81,
		// 			"RQ11_13": 117,
		// 			"RQ11_14": 20,
		// 			"RQ11_15": 41,
		// 			"RQ11_16": 105
		// 		},
		// 		"12": {
		// 			"RQ11_1": 25,
		// 			"RQ11_2": 12,
		// 			"RQ11_3": 32,
		// 			"RQ11_4": 38,
		// 			"RQ11_5": 35,
		// 			"RQ11_6": 27,
		// 			"RQ11_7": 19,
		// 			"RQ11_8": 39,
		// 			"RQ11_9": 41,
		// 			"RQ11_11": 24,
		// 			"RQ11_13": 43,
		// 			"RQ11_14": 7,
		// 			"RQ11_15": 10,
		// 			"RQ11_16": 35
		// 		}
		// 	}
		// }
		
		const formData = new FormData();
		formData.append('file', uploadedFile);
		formData.append('userIntent', question);
		formData.append('brand_col', selectedBrandColValue.name);
		formData.append('cat_col', selectedCatColValue.map(obj => { return obj.name }).join(","))

		axios.post(`${API_BASE}unlock-insights/`, formData).then((res) => {
			let data = res.data
			console.log(data)
			if (typeof (data) === "object" && Object.keys(data).includes('column_counts')) {
				validateInputAndCallAPI(
					{
						data: { "radarChart": data.column_counts }
					}
				)
				setLoading(false)
			}
			else{
				if(data.error){
					console.log(data.error)
					validateInputAndCallAPI(
						{
							data: { "message": data.error }
						}
					)
					setLoading(false)
				}
			}
		}).catch(err => console.error(err))

	}
	

	async function validateInputAndCallAPI(data) {
		// setLoading(true)
		setTimeout(() => {

			// setLoading(false)
			if (question === "") {
				alert("Please provide Question before calling this API");
				return;
			} else {
				let chatLogNew = [...chatLog, { user: "user", message: question }];
				setChatLog(chatLogNew);
				//   setLoading(true);
				//   let response = await axios.post(
				// 	  "https://testil.nlugpt.io/wolverine/intelligenceLayer/",
				// 	  {
				// 		  userIntent: question,
				// 		  file_type: fileType,
				// 		  file_name:filename
				// 	  }
				//   );
				const response = data

				setHistory((previousvalue) => {
					const value = [...previousvalue, question]
					return value
				})
				//   setQuestion("");
				if (response.data) {
					if (response.data.message) {
						const data = response.data.message;
						let newChatLog = [
							...chatLog,
							{ user: "user", message: question, response: { user: "ai", message: `${data}` } }

						];
						setChatLog(newChatLog);
					} else {
						const graph = response.data.pieChart || response.data.barChart || response.data.doughnutChart || response.data.lineChart
							|| response.data.polarareaChart || response.data.radarChart || response.data.scatterChart || response.data.bubbleChart;

						const { dataValues1, stacked } = processGraph(graph);
						//   console.log("================================ stacked", stacked);
						console.log("================================ datavales", dataValues1);
						let newChatLog = [
							...chatLog,
							{
								user: "user", message: question, response:
								{
									user: "ai",
									message: "Here is your visualization:",
									viz: Object.keys(response.data)[0],
									data: Object.entries(graph).map(([x, y]) => ({
										x: `${x} (${y})`,
										y,
									})),
									dataValues1,
									stacked,
									dataValues: Object.values(graph),
									labels: Object.keys(graph)
								}
							}

						];
						console.log(newChatLog)
						setChatLog(newChatLog);
					}
					// setLoading(false);
					return true;
				}

			}
		}, 2200);
	}

	function processGraph(graph) {
		console.log("process graph function----------------------------------------------------------------")

		let stacked = false;

		const dataValues1 = {
			//   label: [],
		};

		if (typeof Object.values(graph)[0] === 'object') {
			const subKeys = Object.keys(graph[Object.keys(graph)[0]]);
			subKeys.forEach(subKey => {
				// dataValues1.label.push(subKey);
				dataValues1[subKey.toLowerCase()] = Object.values(graph).map(item => item[subKey]);
			});
			stacked = true
		} else {
			// dataValues1.label = Object.keys(graph);
			Object.keys(graph).forEach(key => {
				dataValues1[key] = [graph[key]];
			});
			stacked = false
		}
		// console.log("data", dataValues1);
		return { dataValues1, stacked };
	}


	useEffect(() => {

		if (messagesEndRef.current) {
			messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
		}

	}, [isLoading])

	const scrollQuestion = (index) => {
		let id = "section_" + index
		const targetElement = document.getElementById(id);
		// console.log(targetElement)
		if (targetElement) {
			targetElement.scrollIntoView({ behavior: 'smooth' });
		}

	}

	

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const file = e.dataTransfer.files[0];
		console.log(e,"\n",file)
		// if (file && !file.name.endsWith('.xlsx')) {
		// 	setIsCsvSelected(false)
		// }
		// else{
		// 	if(usertier=="paid plan"&&file.size > 150 * 1024 * 1024){
		// 	setIsCsvSelected(false)
		// 	setFileErr("File size is restricted to 150MB")
		// 	}
		// 	else if(usertier=="free plan"&&file.size > 5 * 1024 * 1024){
		// 	setIsCsvSelected(false)
		// 	setFileErr("File size is restricted to 5MB")
		// 	}
		// 	else{
		if(file){
				
			GetCol_names(file)
			setUploadedFile(file);
		}
		// setIsCsvSelected(true)
		// setFileErr('')
		// }
		// }
		console.log("file draged---------------", file)
	};

	const handleBackToUpload = (e) => {
		e.preventDefault()
		setUploadedFile('')
		setChatLog([])
		setBrandcolValue([])
		setCatColValue([])
		setIsChartWindowOpened(false)
	}
	// console.log("-----------up file----------",uploadedFile)
	useEffect(() => {
		const timer = setInterval(() => {
			setUploadProgress((oldProgress) => {
				if (oldProgress === 100) {
					return 0;
				}
				const diff = Math.random() * 10;
				return Math.min(oldProgress + diff, 100);
			});
		}, 100);

		return () => {
			clearInterval(timer);
		};
	}, [uploadedFile])


	const handleEnterPress = (e) => {
		// console.log("--------------", e)
		e.preventDefault()
		if (e.code === "Enter") {
			handleSubmit(e)
		}
	}

	const handleDragOver = (e)=>{
		e.preventDefault()
		e.stopPropagation()
		e.dataTransfer.dropEffect = 'copy'
	}


	return (
		<div className="App">
			<Header />
			{uploadedFile && isChartWindowOpened ? <aside className="sidemenu">
				<div style={{ display: "none" }}>
					<input
						type="file"
						onChange={handleFileChange}
						// accept="text/csv, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						id="dna-input"
					/>
				</div>
				{/* {!questionView &&<h4>Try our sample data</h4>} */}
				<Stack direction="row"  onClick={(e) => handleBackToUpload(e)} sx={{ cursor:"pointer",gap: "8px", alignItems: "center", justifyContent: "flex-start", mb: "24px" }}>
					<ArrowBack />
					Back to upload
				</Stack>
				<Stack sx={{ gap: "16px", textAlign: "left" }}>
					<Typography sx={{ fontSize: "18px", lineHeight: "28px", fontWeight: 400 }}>Source file</Typography>
					<div /* className="sidemenu-button" */ style={{ display: "flex", alignItems: "center", cursor: "pointer", marginBottom: "24px" }}>
						<img style={{ width: "45px", height: "45px" }} src={uploadimg} alt="upload" />
						{uploadedFile.name}
					</div>
				</Stack>
				<Stack sx={{ gap: "24px", textAlign: "left", mb: "24px" }}>
					<Stack sx={{ gap: "8px", width: "100%" }}>
						<Typography>Brand Column <Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
						<Dropdown
							pt={{
								header:{style:{padding:"12px 16px",backgroundColor:"#334155",gap:"8px",justifyContent:"unset"}},
								filterContainer:{style:{width:"100%",padding:"12px",height:"48px",borderRadius:"4px",backgroundColor:"#0F172A",border:"1px solid #64748B",flex:"unset",display:"flex",justifyContent:"center",alignItems:"center",gap:"8px"}},
								filterIcon:{style:{position:"static",marginTop:0,color:"#94A3B8"}},
								filterInput:{style:{backgroundColor:"transparent",color:"#F1F5F9",outline:"none",border:"none"}},
								clearIcon:{style:{position:"static",margin:0,marginRight:"20px"}},
								root:{style:{alignItems:"center"}},
								item:{style:{padding:"12px 16px",height:"48px",gap:"8px"}}
								
							}}
							showClear={true}
							style={{ width: "100%", padding: "12px", backgroundColor: "#334155", border: "1px solid #CBD5E1", borderRadius: "4px" }} filter value={selectedBrandColValue} onChange={(e) => setSelectedBrandcolValue(e.value)} options={brandColValue} optionLabel="name" placeholder="Select Brand Column" />
					</Stack>
					<Stack sx={{ gap: "8px" }}>
						<Typography>Category Columns <Typography component="span" sx={{color:"red"}}>*</Typography></Typography>
						<MultiSelect
							pt={{
								header:{style:{padding:"12px 16px",backgroundColor:"#334155",gap:"8px",justifyContent:"unset"}},
								filterContainer:{style:{width:"175px",padding:"12px",height:"48px",borderRadius:"4px",backgroundColor:"#0F172A",border:"1px solid #64748B",flex:"unset",display:"flex",justifyContent:"center",alignItems:"center",gap:"8px"}},
								filterIcon:{style:{position:"static",marginTop:0}},
								closeButton:{style:{color:"#94A3B8"}},
								item:{style:{padding:"12px 16px",height:"48px",gap:"8px",alignItems:"center"}},
								token:{style:{border:"1px solid #64748B",borderRadius:"10px"}},
								label:{style:{width:"180px"}}
							}}
							display="chip"
							showClear={true} style={{ width: "100%", padding: "12px", backgroundColor: "#334155", border: "1px solid #CBD5E1", borderRadius: "4px" }} filter value={selectedCatColValue} onChange={(e) => setSelectedCatColValue(e.value)} options={CatColValue} optionLabel="name" placeholder="Select Category Columns" />
					</Stack>
				</Stack>
				{
					history.length > 0 ? <div className="history">
						<div className="history_heading">
							<p>History</p></div>
						<div className="history-container">
							{history.map((value, index) => {
								return (
									<div className="history_topics" onClick={() => scrollQuestion(index)}><p className="history_text" >
										{value}
									</p>

									</div>
								)
							}

							)}
						</div>
					</div> : null
				}
			</aside> : null}
			<section className="chatbox" style={{ display: isRadioBtnClicked && !isSendBtnClicked ? "flex" : "block",flexDirection:"column", justifyContent: "center", alignItems: "center" }}>
				{question ||chatLog.length >=1 ? (
					<div className="chat-log" ref={messagesEndRef} style={{width:"100%"}}>
						{isLoading &&
							(
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										height: '100%',
										backgroundColor: '#343541',
										width: "100%"
									}}
								>
									<div className="loading-container">
										<HoopSpinner size={30} color="#fdb813" />
									</div>
								</div>
							)
						}

						{isLoading ? null : chatLog.map((message, index) => (
							<ChatMessage index={index} message={message} />
						))}
					</div>
				)
					:
					<Stack direction="row" sx={{ gap: "324px" }}>
						{!uploadedFile ? <Stack onDrop={(e) => handleDrop(e)} onDragOver={(e) => handleDragOver(e)} sx={{ gap: "30px", alignItems: "center", justifyContent: "center", p: "36px", width: "312px", height: "260px", border: "1px solid #475569", borderRadius: "20px" }}>
							<Stack sx={{ gap: "30px", alignItems: "center", justifyContent: "center" }}>
								<Box component="img" src={Upload_svg} sx={{ width: "96px", height: "96px" }} />
								{/* <Typography sx={{ fontSize: "20px", lineHeight: "28px", fontWeight: 400 }}>You can drag and drop data file to upload</Typography> */}
								<Typography sx={{ fontSize: "20px", lineHeight: "28px", fontWeight: 400 }}>Choose a file</Typography>
							</Stack>
							<Stack>
								<input ref={fileRef} type="file" accept={'.xlsx'} onChange={(e) => { handleManualFileUpload(e) }} style={{ display: "none" }} />
								<Button variant="contained" onClick={() => fileRef.current && fileRef.current.click()}>Browse computer</Button>
							</Stack>
						</Stack>
							:
							uploadedFile && !isChartWindowOpened && brandColValue.length > 0 && CatColValue.length > 0 ? <Stack sx={{ gap: "30px", alignItems: "center", justifyContent: "center", p: "36px", width: "312px", height: "338px", border: "1px solid #475569", borderRadius: "20px" }}>
								<Box component="img" src={Leadership_svg} sx={{ width: "96px", height: "96px" }} />
								<Typography sx={{ fontSize: "20px", lineHeight: "28px", fontWeight: 400 }}>Uploaded successfully and ready for play.</Typography>
								<Button variant="contained" onClick={() => setIsChartWindowOpened(true)} sx={{ p: "12px", width: "73px", height: "48px", borderRadius: "4px", bgcolor: "#3B82F6", color: "#FFFFFF" }}>Go</Button>
								<Stack direction="row" onClick={(e) => handleBackToUpload(e)} sx={{ alignItems: "center", justifyContent: "center", cursor: "pointer", p: "12px", width: "193px", height: "48px", borderRadius: "4px", bgcolor: "transparent", color: "#FFFFFF", gap: "8px" }}>
									<ArrowBack /> Back to upload
								</Stack>
							</Stack>
								:
								!isChartWindowOpened ?
									<Stack sx={{ gap: "30px", alignItems: "center", justifyContent: "center", p: "36px", width: "329px", height: "260px", border: "1px solid #475569", borderRadius: "20px" }}>
										<Box component="img" src={Copy_svg} sx={{ width: "96px", height: "96px" }} />
										<LinearProgress sx={{ width: "100%", height: "12px" }} variant="determinate" value={uploadProgress} />
										<Typography sx={{ fontSize: "20px", lineHeight: "28px", fontWeight: 400 }}>We are uploading your file, please stay with us.</Typography>
									</Stack> : null
						}
						
						{!isChartWindowOpened && <Stack sx={{ width: "613px", gap: "28px", alignItems: "flex-start", justifyContent: "center" }}>
							<Stack direction="row" sx={{ gap: "48px", alignItems: "center", justifyContent: "flex-start" }}>
								<Box component="img" src={Analytics_svg} sx={{ width: "64px", height: "64px" }} />
								<Stack sx={{ gap: "8px", alignItems: "flex-start" }}>
									<Typography sx={{ fontFamily: "Poppins", fontSize: "24px", fontWeight: 700, lineHeight: "32px" }}>Market Analysis</Typography>
									<Typography sx={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: 400, lineHeight: "28px" }}>Upload a file on the left to ask the AI questions.</Typography>
								</Stack>
							</Stack>
							<Stack direction="row" sx={{ gap: "48px", alignItems: "flex-start", justifyContent: "flex-start" }}>
								<Box component="img" src={isRadioBtnClicked === "DA" ? Data_visualization_svg : Radar_svg} sx={{ width: "64px", height: "64px" }} />
								<Stack sx={{ gap: "8px", alignItems: "flex-start" }}>
									<Typography sx={{ fontFamily: "Poppins", fontSize: "24px", fontWeight: 700, lineHeight: "32px" }}>New Feature</Typography>
									<Typography sx={{ width: "500px", fontFamily: "Poppins", fontSize: "18px", fontWeight: 400, lineHeight: "28px", textAlign: "left" }}>A market analysis that has separate charts for every data point feels cluttered. With a radar chart, all your data is gathered into one web, revealing strengths and weaknesses simultaneously. </Typography>
								</Stack>
							</Stack>
							<Stack direction="row" sx={{ gap: "48px", alignItems: "flex-start", justifyContent: "flex-start" }}>
								<Box component="img" src={isRadioBtnClicked === "DA" ? Idea_svg : Privilege_svg} sx={{ width: "64px", height: "64px" }} />
								<Stack sx={{ gap: "8px", alignItems: "flex-start" }}>
									<Typography sx={{ fontFamily: "Poppins", fontSize: "24px", fontWeight: 700, lineHeight: "32px" }}>Benefits</Typography>
									<Typography sx={{ width: "500px", fontFamily: "Poppins", fontSize: "18px", fontWeight: 400, lineHeight: "28px", textAlign: "left" }}>It helps you identify trends, compare competitors, and make smarter decisions.</Typography>
									
								</Stack>
							</Stack>

						</Stack>}
					</Stack>
				}
				
				{uploadedFile && isChartWindowOpened? <TextField
					disabled = {selectedBrandColValue && selectedCatColValue ? false :true}
					value={question}
					InputProps={{
						endAdornment: selectedBrandColValue && selectedCatColValue && question?(
							<InputAdornment>
								<IconButton onClick={(e) => handleSubmit(e)}>
									<NearMe sx={{ color: "#FFF", backgroundColor: "#9746FF", borderRadius: "8px", p: "8px", width: "20px", height: "20px", cursor: "pointer" }} />
								</IconButton>
							</InputAdornment>

						):""
					}} onChange={(e) => setQuestion(e.target.value)}
					inputProps={{ style: { padding: 0, color: selectedBrandColValue && selectedCatColValue ?"#FFF":"#CBD5E1",WebkitTextFillColor:selectedBrandColValue && selectedCatColValue ?"#FFF":"#CBD5E1", height: "55px", border: "none",outline:"none" } }}
					placeholder="Ask your Question" sx={{ position: chatLog.length>=1 && question ?"static":"fixed", bottom: "0px", width: "916px", height: "55px", p: "12px", border: "1px solid #4B5563", borderRadius: "4px", backgroundColor: "#1E293B", mb:chatLog.length>=1?"24px":0}}
				/>:null}
			</section>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				// open={restrictionOpen}
				// onClose={RestrictionhandleClose}
				closeAfterTransition
			>
				<Box sx={{ ...modal_style, textAlign: "center" }}>
					<Typography sx={{ color: "white", mb: 2 }} variant='h5'>
						Out of demo credits? Contact us for more.
					</Typography>
					<Button variant='contained' sx={{ bgcolor: "#fdb813", color: "#33336f", "&:hover": { bgcolor: "#fdb813", color: "#33336f" } }}>

						<Box component="a" href="https://arivu-iq.com/#ourContact" target="_blank" sx={{ textDecoration: "none" }} >Contact US</Box>
					</Button>
				</Box>
			</Modal>
		</div>
	);
}
