import React, { useState,useEffect } from 'react';
import { Card,Box,Button, CardContent, CardMedia,Modal, Typography, TextField, InputBase, Divider, Tooltip, Select,MenuItem, NativeSelect } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; 
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import EditIcon from '@mui/icons-material/Edit';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 370,
  // bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  backgroundColor: '#141313'
};

const SceneDisplay = ({ scene }) => {
  const[indices,setIndices]= useState()
  const [editing,setEditing]=useState(false)
  const [open,setOpen]=useState(false)
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const { currentScene, totalScenes, onSceneChange, scenesData,setScenesData, title, description, image_caption, cyoa_image_base64,modifyDialog,loader,setDialog,dialogs,dialogChange,voice,language,selectedVoice,setSelectedVoice,selectedVoiceChange,setSelectedLanguage,selecetdLanguageChange,selecetdLanguage,scenelanguage,deleteDialog } = scene;


  const handleClose = () => setOpen(false); 
useEffect(()=>{
  setEditing(false)
},[currentScene])


const [selectedValues,setSelectedValues] = useState(0)
const [Values,setValues] = useState('')
let selectedValue=''

document.onmouseup = () => {
  selectedValue = window.getSelection().toString()
  selectedValue.length>0?setSelectedValues(1):setSelectedValues(0)
  setValues(selectedValue)
};


scenesData.forEach((item, ind) => {
  item.id = ind + 1 + "";
});


const hanndleDragDrop = (results) => {
        
  const { source, destination, type } = results;
  if (!destination) return;
  if (source.draggableId === destination.droppableId && source.index === destination.index)
      return;

  const reOrderedTitle = [...scenesData]

  const sourceIndex = source.index;
  const destinationIndex = destination.index;

  const [removedTitle] = reOrderedTitle.splice(sourceIndex, 1)
  reOrderedTitle.splice(destinationIndex, 0, removedTitle)

  setScenesData(reOrderedTitle)
}
const [dialogChanges,setdialogchange] = useState(false)


const scene1 ='Lorem';
const scene2 = ['Lorem1', 'ipsum2'];
const scene3 = ['Lorem3', 'ipsum3'];
// let count = 0
// if(count>2){
//   count = 0
// }
const diagogChange = (i)=>{
  setdialogchange(true)
  dialogChanges===true ?description[i].text=scene1:description[i].text   
 console.log(description)
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <DragDropContext
                        onDragEnd={hanndleDragDrop}
                    >
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} p={4}>
            <Grid item xs={12} sm={2} md={2} >
            {/* <Droppable droppableId="titleBox" type="group">
                        {(provided) => (
                            <Box ref={provided.innerRef} {...provided.droppableProps}  {...provided.draggableProps} > */}
                { isMobile ? 
                (Array.from({ length: totalScenes }, (_, index) => (
                    <button
                      key={index}
                      style={{backgroundColor: `${index === currentScene ? '#1976d2' : '#bfbfbf'}`,padding:"8px", width:"36px",borderRadius:"32px", border:"0",marginRight:"8px"}}
                      onClick={() => onSceneChange(index)}
                    >
                      {index + 1}
                    </button>
                  ))):
                  (
                scenesData.map((_, index) => {
                  return(
              //     <Draggable
              //     key={index}
              //     draggableId={_.id}
              //     index={index}
              //     bounds="parent"
              // >
              //     {(provided) => (
                       <Box >
                    <Box component="img"
                    sx={{width: "100%"}}
                    key={index}
                    src={_.cyoa_image_base64}
                    className={index === currentScene ? 'active' : ''}
                    onClick={() => onSceneChange(index)}
                    mb={2}
                    /></Box>
                    // )}
                    // </Draggable>  
                )})
                )}
                 {/* {provided.placeholder}
                            </Box>  
                        )}
                    </Droppable> */}
            </Grid>
            <Grid item xs={12} sm={8} md={8} >
                <Box sx={{width:"100%", height:"100%"}}>
                  {/* need to be draged */}
                    <Box component="img" sx={{width:"inherit"}} src={cyoa_image_base64}/>
                </Box>
            </Grid>
            <Grid item xs={12} sm={2} md={2} >
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >{ description.length>0 ?
              <select   id="demo-simple-select-helper" style={{background:"#333",color:"#fff",padding:"4px",}}  onChange={(e)=>{setSelectedLanguage(e.target.value);selecetdLanguageChange(e.target.value)}}>
                  
                  {language.map((item,index)=>{
                  return <option key={index} value={item.code} style={{color:'white'}}>{item.language}</option>
                  
                })}
                </select>:null

            }
              
                      
                {  description.map((dialogue, index) => (
                  
                    <React.Fragment key={index}>
                      
                   
                        <select  id="demo-simple-select-helper" style={{background:"#333",color:"#fff",padding:"4px",margin:"5px 0px 5px 0px"}} value={dialogue.voice} onChange={(e)=>{setSelectedVoice(e.target.value);selectedVoiceChange(e.target.value,index)}}>
                        {/* <option  defaultValue={dialogue.voice}   style={{color:'black'}}>{dialogue.voice}</option> */}
                        {voice.map((item,index)=>{
                        return  <option key={index} value={item.code} style={{color:'#fff'}}>{item.voice}</option>
                        
                      })}
                      </select>
                      
                   
                    
                    <Box className="dialog-box" bgcolor="#333" color="#fff" sx={loader && index==indices?{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}:null} p={2} mb={2}>
                      {loader && index==indices ? 
                  <div className="dot-animation">
                  <span></span>
                  <span></span>
                  <span></span>
              </div>: !editing && (index!==indices||index==indices) &&
                      <Typography  key={index} >{dialogue.text}</Typography> || editing && index==indices && <InputBase component={TextField} type='text' value={dialogs} sx={{height:"auto",color:"#fff"}} multiline={true} onChange={(e)=>dialogChange(e.target.value,index)} /> || editing && index!==indices && <Typography>{dialogue.text}</Typography> 
                }
                <Divider sx={{borderBlockColor:"#c1c1c1",marginTop:"5px",border:"0.0001 solid #919191"}}/>
                <Box className="dialog-update" sx={{display:"flex",justifyContent:"end",alignSelf:"self-end",marginTop:"5px"}}> 
                <Tooltip title="Regenerate" sx={{fontSize:"16px"}} arrow placement="top-start">
                {/* <Box onClick={()=>{selectedValues==1 ?modifyDialog(Values,index):modifyDialog(dialogue.text,index);setIndices(index);setEditing(false)}} variant='contained'  sx={{height:"40px",width:"40px",display:"flex",alignItems:"center",bgcolor:"none",justifyContent:"center",borderRadius:"100%","&:hover":{bgcolor:"#060606",borderRadius:"100%",display:"flex",alignItems:"center",justifyContent:"center",cursor:"pointer"}}}>
                <SettingsBackupRestoreIcon/>
                  </Box> */}
                  <Box onClick={()=> setOpen(true)} variant='contained'  sx={{height:"40px",width:"40px",display:"flex",alignItems:"center",bgcolor:"none",justifyContent:"center",borderRadius:"100%","&:hover":{bgcolor:"#060606",borderRadius:"100%",display:"flex",alignItems:"center",justifyContent:"center",cursor:"pointer"}}}>
                <SettingsBackupRestoreIcon/>
                  </Box>
                </Tooltip>
                <Tooltip title="Edit Dialog" arrow placement="top-start">
                  <Box onClick={()=> setOpen(true)} sx={{height:"40px",width:"40px",display:"flex",alignItems:"center",justifyContent:"center","&:hover":{bgcolor:"#060606",borderRadius:"100%",display:"flex",alignItems:"center",justifyContent:"center",cursor:"pointer"}}} > 
                  <EditIcon/>
                  </Box>
                  </Tooltip>
                  <Tooltip title="Delete Dialog" arrow placement="top-start">
                  <Box  onClick={()=> setOpen(true)}  sx={{height:"40px",width:"40px",display:"flex",alignItems:"center",justifyContent:"center","&:hover":{bgcolor:"#060606",borderRadius:"100%",display:"flex",alignItems:"center",justifyContent:"center",cursor:"pointer"}}} > 
                  <DeleteIcon/>
                  </Box>
                  </Tooltip>
                  </Box>
                </Box>
                </React.Fragment>
                ))}
            </motion.div>
            </Grid>
        </Grid>
        </DragDropContext>
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => handleClose()}
        closeAfterTransition
      >
          <Box sx={{...style,textAlign:"center"}}>
            <Typography sx={{ color: "white", mb:2 }} variant='h6'>
            Ready to experience the full potential of our AI platform? Contact us to explore additional features.
            </Typography>
            <Button variant='contained' sx={{ backgroundImage: "linear-gradient(90deg, rgb(251, 129, 35) 0%, rgb(227, 66, 128) 94.8%)", color: "#33336f", "&:hover": { bgcolor: "#fdb813", color: "#33336f" } }}>

              <Box component="a" href="https://arivu-iq.com/#ourContact" target="_blank" sx={{textDecoration:"none"}} >Contact US</Box>
            </Button>
          </Box>
      </Modal>
    </motion.div>
  );
};

export default SceneDisplay;