import "../styles/DNA.css";
import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

import { Box, Button,TextareaAutosize, TextField, Checkbox, Divider, Typography, Modal, CircularProgress, Tooltip ,Grid, Card, CardContent, Stack, Menu, MenuItem, MenuList, ListItem, ListItemIcon, ListItemText, CardMedia,List,ListItemButton,Radio,InputBase, RadioGroup, FormControlLabel, FormGroup } from "@mui/material"

import Chart from 'chart.js/auto';
import { getRelativePosition } from 'chart.js/helpers';
import { Bar, Line, Pie, Doughnut, PolarArea, Radar, Scatter, Bubble } from 'react-chartjs-2';

import { VictoryBar, VictoryPie } from "victory";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import jwtDecode from "jwt-decode";
import uploadimg from "../assets/depositphotos_416951984-stock-illustration-vector-csv-file-icon-removebg-preview (2).png"
import { HoopSpinner } from "react-spinners-kit";
import { Close } from "@mui/icons-material";
import Analytics_svg from "../assets/Analytics.svg"
import Radar_svg from "../assets/Radar.svg"
import Privilege_svg from "../assets/Privilege.svg"
import Data_visualization_svg from "../assets/Data_visualization.svg"
import Idea_svg from "../assets/Idea.svg"
const ChatMessage = React.memo(({index, message, ma }) => {
	const response = message.response;
	// console.log(response)
	// console.log("--------------ma-------------",ma)
	const isDesktopLg = useMediaQuery({ query: '(min-width: 1536px)' });
	let data;
	let data1;
	let combinedData;
	const [isCombinedChartClicked,setIsCombinedChartClicked] = useState(false)
	const modal_style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 370,
		// bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
		backgroundColor: '#141313'
	};
	const colors = [
				'rgba(75, 192, 192, 0.8)', // Teal
				'rgba(255, 99, 132, 0.8)', // Red
				'rgba(54, 162, 235, 0.8)', // Blue
				'rgba(255, 159, 64, 0.8)', // Orange
				'rgba(153, 102, 255, 0.8)', // Purple
				'rgba(255, 205, 86, 0.8)', // Yellow
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
				'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
				'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
				'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
				'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
				'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
				'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
			  ]
	if(message.response.viz === "barChart" && message.response.stacked) {
		
		const datasets2 = Object.keys(message.response.dataValues1).map((key, index) => {
			const backgroundColor = index % 2 === 0 ? colors : colors;
			const borderColor = index % 2 === 0 ? colors :  colors;
			
			return {
			  label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
			  data: message.response.dataValues1[key],
			  backgroundColor: backgroundColor,
			  borderColor: borderColor,
			  borderWidth: 1
			};
		  });

		data = {
			labels: message.response.labels,
			datasets: datasets2
		};

	}
	else if(message.response.viz === "doughnutChart" && message.response.stacked) {
		
		const datasets2 = Object.keys(message.response.dataValues1).map((key, index) => {
			const backgroundColor = index % 2 === 0 ? colors: colors;
			const borderColor = index % 2 === 0 ? colors :  colors;
			
			return {
			  label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
			  data: message.response.dataValues1[key],
			  backgroundColor: backgroundColor,
			  borderColor: borderColor,
			  borderWidth: 1
			};
		  });

		data = {
			labels: message.response.labels,
			datasets: datasets2
		};

	}
	else if(message.response.viz === "pieChart" && message.response.stacked) {
		
		const datasets2 = Object.keys(message.response.dataValues1).map((key, index) => {
			const backgroundColor = index % 2 === 0 ? colors : colors;
			const borderColor = index % 2 === 0 ? colors  :  colors;
			
			return {
			  label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
			  data: message.response.dataValues1[key],
			  backgroundColor: backgroundColor,
			  borderColor: borderColor,
			  borderWidth: 1
			};
		  });

		data = {
			labels: message.response.labels,
			datasets: datasets2
		};

	}
	else if(message.response.viz === "lineChart" && message.response.stacked) {
		
		const datasets2 = Object.keys(message.response.dataValues1).map((key, index) => {
			const backgroundColor = index % 2 === 0 ? colors: colors;
			const borderColor = index % 2 === 0 ? colors :  colors;
			
			return {
			  label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
			  data: message.response.dataValues1[key],
			  backgroundColor: backgroundColor,
			  borderColor: borderColor,
			  borderWidth: 1
			};
		  });

		data = {
			labels: message.response.labels,
			datasets: datasets2
		};

	}
	else if(message.response.viz === "polarareaChart" && message.response.stacked) {
		
		const datasets2 = Object.keys(message.response.dataValues1).map((key, index) => {
			const backgroundColor = index % 2 === 0 ? colors : colors;
			const borderColor = index % 2 === 0 ? colors : colors;
			
			return {
			  label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
			  data: message.response.dataValues1[key],
			  backgroundColor: backgroundColor,
			  borderColor: borderColor,
			  borderWidth: 1
			};
		  });

		data = {
			labels: message.response.labels,
			datasets: datasets2
		};

	}
	else if(message.response.viz === "radarChart"){
		data={
			labels: Object.keys(message.response.dataValues[0]),
			datasets:[
				{
					label:message.response.labels[0],
					data:Object.values(message.response.dataValues[0]),
					backgroundColor:'#0EA5E960',
					borderColor:'#7DD3FC'

				}
			],
			backgroundColor: [
				'rgba(75, 192, 192, 0.8)', // Teal
				'rgba(255, 99, 132, 0.8)', // Red
				'rgba(54, 162, 235, 0.8)', // Blue
				'rgba(255, 159, 64, 0.8)', // Orange
				'rgba(153, 102, 255, 0.8)', // Purple
				'rgba(255, 205, 86, 0.8)', // Yellow
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
				'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
				'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
				'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
				'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
				'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
				'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
			],
			borderColor: [
			'rgba(75, 192, 192, 1)',
			'rgba(255, 99, 132, 1)',
			'rgba(54, 162, 235, 1)',
			'rgba(255, 159, 64, 1)',
			'rgba(153, 102, 255, 1)',
			'rgba(255, 205, 86, 1)',
			'rgba(75, 192, 192, 1)',
			'rgba(255, 99, 132, 1)',
			'rgba(54, 162, 235, 1)',
			'rgba(255, 159, 64, 1)',
			'rgba(153, 102, 255, 1)',
			'rgba(255, 205, 86, 1)',
			'rgba(75, 192, 192, 1)',
			'rgba(255, 99, 132, 1)',
			'rgba(54, 162, 235, 1)',
			'rgba(255, 159, 64, 1)',
			'rgba(153, 102, 255, 1)',
			'rgba(255, 205, 86, 1)',
			'rgba(75, 192, 192, 1)',
			'rgba(255, 99, 132, 1)',
			],
			borderWidth: 1,
		}

		// console.log(data)
		data1={
			labels: Object.keys(message.response.dataValues[0]),
			datasets:[
				{
					label:message.response.labels[1],
					data:Object.values(message.response.dataValues[1]),
					backgroundColor:'#EC489960',
					borderColor:'#F9A8D4',
				}

			],
			backgroundColor: [
				'rgba(255, 99, 132, 0.8)', // Red
				'rgba(54, 162, 235, 0.8)', // Blue
				'rgba(255, 159, 64, 0.8)', // Orange
				'rgba(153, 102, 255, 0.8)', // Purple
				'rgba(255, 205, 86, 0.8)', // Yellow
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
				'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
				'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
				'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
				'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
				'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
				'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
			],
			borderColor: [
			'rgba(255, 99, 132, 1)',
			'rgba(54, 162, 235, 1)',
			'rgba(255, 159, 64, 1)',
			'rgba(153, 102, 255, 1)',
			'rgba(255, 205, 86, 1)',
			'rgba(75, 192, 192, 1)',
			'rgba(255, 99, 132, 1)',
			'rgba(54, 162, 235, 1)',
			'rgba(255, 159, 64, 1)',
			'rgba(153, 102, 255, 1)',
			'rgba(255, 205, 86, 1)',
			'rgba(75, 192, 192, 1)',
			'rgba(255, 99, 132, 1)',
			'rgba(54, 162, 235, 1)',
			'rgba(255, 159, 64, 1)',
			'rgba(153, 102, 255, 1)',
			'rgba(255, 205, 86, 1)',
			'rgba(75, 192, 192, 1)',
			'rgba(255, 99, 132, 1)',
			],
			borderWidth: 2
		}
		// console.log(data1)
		combinedData = {
			labels: Object.keys(message.response.dataValues[0]),
			datasets:[
				{
					label:message.response.labels[0],
					data:Object.values(message.response.dataValues[0]),
					backgroundColor:'#0EA5E960',
					borderColor:'#7DD3FC'

				},
				{
					label:message.response.labels[1],
					data:Object.values(message.response.dataValues[1]),
					backgroundColor:'#EC489960',
					borderColor:'#F9A8D4',

				}
			],
			backgroundColor: [
				'rgba(75, 192, 192, 0.8)', // Teal
				'rgba(255, 99, 132, 0.8)', // Red
				'rgba(54, 162, 235, 0.8)', // Blue
				'rgba(255, 159, 64, 0.8)', // Orange
				'rgba(153, 102, 255, 0.8)', // Purple
				'rgba(255, 205, 86, 0.8)', // Yellow
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
				'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
				'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
				'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
				'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
				'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
				'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
			],
			borderColor: [
			'rgba(75, 192, 192, 1)',
			'rgba(255, 99, 132, 1)',
			'rgba(54, 162, 235, 1)',
			'rgba(255, 159, 64, 1)',
			'rgba(153, 102, 255, 1)',
			'rgba(255, 205, 86, 1)',
			'rgba(75, 192, 192, 1)',
			'rgba(255, 99, 132, 1)',
			'rgba(54, 162, 235, 1)',
			'rgba(255, 159, 64, 1)',
			'rgba(153, 102, 255, 1)',
			'rgba(255, 205, 86, 1)',
			'rgba(75, 192, 192, 1)',
			'rgba(255, 99, 132, 1)',
			'rgba(54, 162, 235, 1)',
			'rgba(255, 159, 64, 1)',
			'rgba(153, 102, 255, 1)',
			'rgba(255, 205, 86, 1)',
			'rgba(75, 192, 192, 1)',
			'rgba(255, 99, 132, 1)',
			],
			borderWidth: 1,
		}
		// console.log("---------cd-------",combinedData)


	}
	else{
		data = {
			// labels: ['My First Dataset', 'My second Dataset', 'My third Dataset', 'My Four Dataset', 'My five Dataset'],
			labels: message.response.labels,
			datasets: [{
			//   label: message.labels,
			data: message.response.dataValues,
			//   data: [59, 81, 56, 55, 40],
			backgroundColor: [
				'rgba(75, 192, 192, 0.8)', // Teal
				'rgba(255, 99, 132, 0.8)', // Red
				'rgba(54, 162, 235, 0.8)', // Blue
				'rgba(255, 159, 64, 0.8)', // Orange
				'rgba(153, 102, 255, 0.8)', // Purple
				'rgba(255, 205, 86, 0.8)', // Yellow
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
				'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
				'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
				'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
				'rgba(255, 159, 64, 0.8)', // Orange (Duplicate)
				'rgba(153, 102, 255, 0.8)', // Purple (Duplicate)
				'rgba(255, 205, 86, 0.8)', // Yellow (Duplicate)
				'rgba(75, 192, 192, 0.8)', // Teal (Duplicate)
				'rgba(255, 99, 132, 0.8)', // Red (Duplicate)
				'rgba(54, 162, 235, 0.8)', // Blue (Duplicate)
			  ],
			  borderColor: [
				'rgba(75, 192, 192, 1)',
				'rgba(255, 99, 132, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(255, 159, 64, 1)',
				'rgba(153, 102, 255, 1)',
				'rgba(255, 205, 86, 1)',
				'rgba(75, 192, 192, 1)',
				'rgba(255, 99, 132, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(255, 159, 64, 1)',
				'rgba(153, 102, 255, 1)',
				'rgba(255, 205, 86, 1)',
				'rgba(75, 192, 192, 1)',
				'rgba(255, 99, 132, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(255, 159, 64, 1)',
				'rgba(153, 102, 255, 1)',
				'rgba(255, 205, 86, 1)',
				'rgba(75, 192, 192, 1)',
				'rgba(255, 99, 132, 1)',
			  ],
			  borderWidth: 1
			}]
		  };
	}

	// console.log("================================", datasets2)
	  const chartoption = {
		aspectRatio: 2,
		scales: {
			x: {
			  ticks: {
				color: 'white', // Change this to the desired color
				display: message.response.viz === "pieChart" || message.response.viz === "doughnutChart" || message.response.viz === "polarareaChart" ? false : true
				
				},
			},
			y: {
				ticks: {
				color: 'white', // Change this to the desired color
				display: message.response.viz === "pieChart" || message.response.viz === "doughnutChart" || message.response.viz === "polarareaChart" ? false : true

			  },
			},
		  },
		onClick: (event, elements) => {
		  if (elements.length > 0) {
			const datasetIndex = elements[0].datasetIndex;
			const dataIndex = elements[0].index;
			const dataValue = event.chart.data.datasets[datasetIndex].data[dataIndex];
			// console.log(`Clicked on datasetIndex: ${datasetIndex}, dataIndex: ${dataIndex}, dataValue: ${dataValue}`);
		  }
		},
		responsive: true,
		maintainAspectRatio: true,
		plugins: {
		  legend: {
			onClick: (e, legendItem, legend) => {
				const chart = legend.chart;
				const datasetIndex = chart.data.labels.indexOf(legendItem.text)
				chart.toggleDataVisibility(datasetIndex)
				chart.update(); 
			  },
			display:true,
			position:'right',
			labels: {		
				generateLabels: (chart) => {
					let visibility = []
					for(let i=0;i<chart.data.labels.length;i++){
						if(chart.getDataVisibility(i)===true){
							visibility.push(false)
						}else{
							visibility.push(true)
						}
					}
				  const originalLabels = chart.data.labels;
				  const originalColors = chart.data.datasets[0].backgroundColor;
				  const labels = [];
		
				  originalLabels.forEach((label, index) => {
					labels.push({
					  text: label,
					  fontColor: 'white',
					  fillStyle: originalColors[index],
					  hidden:visibility[index]
					});
				  });
		
				  return labels;
				},
			  },
		  },
		  title: {
			display: true,
			text: 'Visualization',
			color: 'white',
		  },
		},
	};

	if(ma){

		var Radarchartoption1 = {
		  aspectRatio: 1,
		  scales: {
			  x: {
				  display:message.response.viz === "radarChart"?false:true,
				ticks: {
				  color: 'white', // Change this to the desired color
				  display: message.response.viz === "pieChart" || message.response.viz === "doughnutChart" || message.response.viz === "polarareaChart" || message.response.viz === "radarChart" ? false : true
  
				},
				grid:{
				  display: message.response.viz === "radarChart"?false:true
				}
			  },
			  y: {
				  display:message.response.viz === "radarChart"?false:true,
				ticks: {
				  color: 'white', // Change this to the desired color
				  display: message.response.viz === "pieChart" || message.response.viz === "doughnutChart" || message.response.viz === "polarareaChart" || message.response.viz === "radarChart" ? false : true
  
				},
				grid:{
				  display: message.response.viz === "radarChart"?false:true
				}
			  },
			  r:{
				  grid:{
					  color:"#BDBDBD"
				  },
				  angleLines: {
					  display:true,
					  color: '#BDBDBD'
				  },
				  ticks:{
					  color: '#BDBDBD',
					  backdropPadding:5,
					  backdropColor:"#343541",
					  // stepSize : 10,
				  },
				  pointLabels:{
					  color:"#BDBDBD"
				  }
			  }
			},
		  onClick: (event, elements) => {
			if (elements.length > 0) {
			  const datasetIndex = elements[0].datasetIndex;
			  const dataIndex = elements[0].index;
			  const dataValue = event.chart.data.datasets[datasetIndex].data[dataIndex];
			  // console.log(`Clicked on datasetIndex: ${datasetIndex}, dataIndex: ${dataIndex}, dataValue: ${dataValue}`);
			}
		  },
		  responsive: true,
		  maintainAspectRatio: true,
		  plugins: {
			legend: {
			//   onClick: (e, legendItem, legend) => {
			// 	console.log("----coming---",legendItem)
			// 	  const chart = legend.chart;
			// 	  const datasetIndex = chart.data.datasets.map((e)=>{return e.label}).indexOf(legendItem.text)
			// 	  chart.toggleDataVisibility(datasetIndex)
			// 	  chart.update(); 
			// 	},
			  display: message.response.viz === "radarChart" && !isCombinedChartClicked?false:true,
			  position:'bottom',
			  labels: {		
				color:"#FFFFFF",
				font:{
					family:"Poppins",
					size:"14px",
					style:"normal",
					weight:600
				}
				// 	  generateLabels: (chart) => {
				// 		  let visibility = []
				// 		  for(let i=0;i<chart.data.labels.length;i++){
				// 			  if(chart.getDataVisibility(i)===true){
				// 				  visibility.push(false)
				// 			  }else{
				// 				  visibility.push(true)
				// 			  }
				// 		  }
				// 		const originalLabels = chart.data.labels;
				// 		const originalColors = chart.data.datasets[0].backgroundColor;
				// 		const labels = [];
		  
				// 		originalLabels.forEach((label, index) => {
				// 		  labels.push({
				// 			text: label,
				// 			fontColor: 'white',
				// 			fillStyle: originalColors[index],
				// 			hidden:visibility[index]
				// 		  });
				// 		});
		  
				// 		return labels;
				// 	  },
				},
			},
			title: {
			  display: false,
			  text:message.response.labels[0] == 1 ? "Westside" : "Max",
			  color: 'white',
			},
		  },
		};
		var Radarchartoption2 = {
		  aspectRatio: 1,
		  scales: {
			  x: {
				  display:message.response.viz === "radarChart"?false:true,
				ticks: {
				  color: 'white', // Change this to the desired color
				  display: message.response.viz === "pieChart" || message.response.viz === "doughnutChart" || message.response.viz === "polarareaChart" || message.response.viz === "radarChart" ? false : true
  
				},
				grid:{
				  display: message.response.viz === "radarChart"?false:true
				}
			  },
			  y: {
				  display:message.response.viz === "radarChart"?false:true,
				ticks: {
				  color: 'white', // Change this to the desired color
				  display: message.response.viz === "pieChart" || message.response.viz === "doughnutChart" || message.response.viz === "polarareaChart" || message.response.viz === "radarChart" ? false : true
  
				},
				grid:{
				  display: message.response.viz === "radarChart"?false:true
				}
			  },
			  r:{
				  // suggestedMax:100,
				  grid:{
					  color:"#BDBDBD"
				  },
				  angleLines: {
					  display:true,
					  color: '#BDBDBD'
				  },
				  ticks:{
					  color: '#BDBDBD',
					  backdropPadding:5,
					  backdropColor:"#343541",
					  // stepSize : 30,
				  },
				  pointLabels:{
					  color:"#BDBDBD"
				  }
			  }
			},
		  onClick: (event, elements) => {
			if (elements.length > 0) {
			  const datasetIndex = elements[0].datasetIndex;
			  const dataIndex = elements[0].index;
			  const dataValue = event.chart.data.datasets[datasetIndex].data[dataIndex];
			  // console.log(`Clicked on datasetIndex: ${datasetIndex}, dataIndex: ${dataIndex}, dataValue: ${dataValue}`);
			}
		  },
		  responsive: true,
		  maintainAspectRatio: true,
		  plugins: {
			legend: {
			  onClick: (e, legendItem, legend) => {
				  const chart = legend.chart;
				  const datasetIndex = chart.data.labels.indexOf(legendItem.text)
				  chart.toggleDataVisibility(datasetIndex)
				  chart.update(); 
				},
			  display: message.response.viz === "radarChart"?false:true,
			  position:'right',
			//   labels: {		
			// 	  generateLabels: (chart) => {
			// 		  let visibility = []
			// 		  for(let i=0;i<chart.data.labels.length;i++){
			// 			  if(chart.getDataVisibility(i)===true){
			// 				  visibility.push(false)
			// 			  }else{
			// 				  visibility.push(true)
			// 			  }
			// 		  }
			// 		const originalLabels = chart.data.labels;
			// 		const originalColors = chart.data.datasets[0].backgroundColor;
			// 		const labels = [];
		  
			// 		originalLabels.forEach((label, index) => {
			// 		  labels.push({
			// 			text: label,
			// 			fontColor: 'white',
			// 			fillStyle: originalColors[index],
			// 			hidden:visibility[index]
			// 		  });
			// 		});
		  
			// 		return labels;
			// 	  },
			// 	},
			},
			title: {
			  display: false,
			  text:message.response.labels[1] == 12 ? "Fabindia": null,
			  color: 'white',
			},
		  },
		};
		var CombinedRadarchartoption1 = {
			aspectRatio: 1,
			scales: {
				x: {
					display:message.response.viz === "radarChart"?false:true,
				  ticks: {
					color: 'white', // Change this to the desired color
					display: message.response.viz === "pieChart" || message.response.viz === "doughnutChart" || message.response.viz === "polarareaChart" || message.response.viz === "radarChart" ? false : true
	
				  },
				  grid:{
					display: message.response.viz === "radarChart"?false:true
				  }
				},
				y: {
					display:message.response.viz === "radarChart"?false:true,
				  ticks: {
					color: 'white', // Change this to the desired color
					display: message.response.viz === "pieChart" || message.response.viz === "doughnutChart" || message.response.viz === "polarareaChart" || message.response.viz === "radarChart" ? false : true
	
				  },
				  grid:{
					display: message.response.viz === "radarChart"?false:true
				  }
				},
				r:{
					grid:{
						color:"#BDBDBD"
					},
					angleLines: {
						display:true,
						color: '#BDBDBD'
					},
					ticks:{
						color: '#BDBDBD',
						backdropPadding:5,
						backdropColor:"#374151",
						// stepSize : 10,
					},
					pointLabels:{
						color:"#BDBDBD"
					}
				}
			  },
			onClick: (event, elements) => {
			  if (elements.length > 0) {
				const datasetIndex = elements[0].datasetIndex;
				const dataIndex = elements[0].index;
				const dataValue = event.chart.data.datasets[datasetIndex].data[dataIndex];
				// console.log(`Clicked on datasetIndex: ${datasetIndex}, dataIndex: ${dataIndex}, dataValue: ${dataValue}`);
			  }
			},
			responsive: true,
			maintainAspectRatio: true,
			plugins: {
			  legend: {
			  //   onClick: (e, legendItem, legend) => {
			  // 	console.log("----coming---",legendItem)
			  // 	  const chart = legend.chart;
			  // 	  const datasetIndex = chart.data.datasets.map((e)=>{return e.label}).indexOf(legendItem.text)
			  // 	  chart.toggleDataVisibility(datasetIndex)
			  // 	  chart.update(); 
			  // 	},
				display: message.response.viz === "radarChart" && !isCombinedChartClicked?false:true,
				position:'bottom',
				labels: {		
				  color:"#FFFFFF",
				  font:{
					  family:"Poppins",
					  size:"14px",
					  style:"normal",
					  weight:600
				  }
				  // 	  generateLabels: (chart) => {
				  // 		  let visibility = []
				  // 		  for(let i=0;i<chart.data.labels.length;i++){
				  // 			  if(chart.getDataVisibility(i)===true){
				  // 				  visibility.push(false)
				  // 			  }else{
				  // 				  visibility.push(true)
				  // 			  }
				  // 		  }
				  // 		const originalLabels = chart.data.labels;
				  // 		const originalColors = chart.data.datasets[0].backgroundColor;
				  // 		const labels = [];
			
				  // 		originalLabels.forEach((label, index) => {
				  // 		  labels.push({
				  // 			text: label,
				  // 			fontColor: 'white',
				  // 			fillStyle: originalColors[index],
				  // 			hidden:visibility[index]
				  // 		  });
				  // 		});
			
				  // 		return labels;
				  // 	  },
				  },
			  },
			  title: {
				display: false,
				text:message.response.labels[0] == 1 ? "Westside" : "Max",
				color: 'white',
			  },
			},
		  };
		// console.log(Radarchartoption1)
	}

	// console.log("---------",isCombinedChartClicked)

	  const value="section_"+index
	  
	//////////////////////////////////////////////////////////////// REFERENECE FOR STACKED BAR CHARTS //////////////////////////////////////////////////////////////////
	//   const chartData1 = {
	// 	labels: ["India", "China", "Malaysia"],
	// 	datasets: [
	// 	  {
	// 		label: 'Male',
	// 		backgroundColor: 'rgba(54, 162, 235, 0.6)', // Blue
	// 		borderColor: 'rgb(54, 162, 235)',
	// 		borderWidth: 1,
	// 		data: [200, 180, 150]
	// 	  },
	// 	  {
	// 		label: 'Female',
	// 		backgroundColor: 'rgba(255, 99, 132, 0.6)', // Pink
	// 		borderColor: 'rgb(255, 99, 132)',
	// 		borderWidth: 1,
	// 		data: [132, 120, 110]
	// 	  }
	// 	]
	//   };
	
	//   const chartoption1 = {
	// 	scales: {
	// 	  x: { stacked: true },
	// 	  y: { stacked: true }
	// 	}
	//   };

	return (
		<Grid id={value} container p={4}>
		<Grid sx={{borderBottom:ma?0:"2px solid grey"}} item xs={12}>
				
			<Box>
				<h1 style={{color:"#11dd2a",marginBottom:0}}>
				{message.message}
				</h1>
				{
					ma?
					<FormControlLabel sx={{my:"24px"}}  control={<Checkbox sx={{color:"#FFF","&.Mui-checked":{color:"#EC4899"}}} checked={isCombinedChartClicked} onClick={()=>setIsCombinedChartClicked(!isCombinedChartClicked)}/>} label="Show Combined Chart"/>
					:null
				}
			</Box>
			{ma?null:<Box>
			<h3>
				{message.response.message}
			</h3>
			</Box>}
			<Box>
			{message.response.viz === "pieChart" && (
				<>
					<Box className="chart-container" sx={{width:"800px",height:"400px"}}>
						<Pie data={data} options={chartoption} />
					</Box>
				</>
			)}
			{/* ////////////REFERENCE FOR STACKED BAR CHARTS//////////// */}
			{/* {message.response.viz === "barChart" && (
				<>
					<Box className="chart-container" sx={{width:"800px",textAlign:"-webkit-center"}} >
						<Bar data={chartData1} option={chartoption1} />
					</Box>
				</>
			)} */}
			{message.response.viz === "barChart" && (
				<>
					<Box className="chart-container" sx={{width:"800px",height:"400px"}} >
						<Bar data={data} options={chartoption} />
					</Box>
				</>
			)}
			{message.response.viz === "doughnutChart" && (
				<>
					<Box className="chart-container" sx={{width:"1000px",height:"400px"}} >
						<Doughnut data={data} options={chartoption} />
					</Box>
				</>
			)}
			{message.response.viz === "lineChart" && (
				<>
					<Box className="chart-container" sx={{width:"800px",height:"400px"}} >
						<Line data={data} options={chartoption} />
					</Box>
				</>
			)}
			{message.response.viz === "polarareaChart" && (
				<>
					<Box className="chart-container" sx={{width:"800px",textAlign:"-webkit-center"}} >
						<PolarArea data={data} options={chartoption} />
					</Box>
				</>
			)}
			{message.response.viz === "radarChart" && (
				<>
				<Grid container sx={{border: "1px solid #64748B",justifyContent:isDesktopLg?"space-between":"center",p:"24px",width:isDesktopLg?"1298px":"100%",textAlign:isDesktopLg?"unset":"center"}}>
					<Grid item sx={{pr:"36px"}}>
						<Box sx={{mb:"19px",fontFamily:"Poppins",fontSize:"20px",fontWeight:700,lineHeight:"28px"}}>
							<Typography>{message.response.labels[0]}</Typography>
						</Box>
						<Box className="chart-container" sx={{width:"500px",textAlign:"-webkit-center"}} >
							<Radar 
							data={data} options={Radarchartoption1} />
						</Box>
					</Grid>
					{
						isDesktopLg? <Divider orientation="vertical" sx={{borderWidth:"1px",bgcolor:"#64748B",height:"540px"}}/>
						:
						<Divider sx={{borderWidth:"1px",bgcolor:"#64748B",width:"100%",mb:"24px"}}/>
					}
					<Grid item  sx={{pr:"36px"}}>
						<Box sx={{mb:"19px",fontFamily:"Poppins,sans-serif !important",fontSize:"20px",fontWeight:700,lineHeight:"28px"}}>
							<Typography>{message.response.labels[1]}</Typography>
						</Box>
						<Box className="chart-container" sx={{width:"500px",textAlign:"-webkit-center"}} >
							<Radar 
							data={data1} options={Radarchartoption2} />
						</Box>
					</Grid>
				</Grid>
				</>
			)}
			{message.response.viz === "scatterChart" && (
				<>
					<Box className="chart-container" sx={{width:isDesktopLg?"500px":"300px",textAlign:"-webkit-center"}} >
						<Scatter data={data} options={chartoption} />
					</Box>
				</>
			)}
			{message.response.viz === "bubbleChart" && (
				<>
					<Box className="chart-container" sx={{width:isDesktopLg?"500px":"300px",textAlign:"-webkit-center"}} >
						<Bubble data={data} options={chartoption} />
					</Box>
				</>
			)}
			</Box>
		</Grid>

		{ma?<Modal
				open={isCombinedChartClicked}
				// closeAfterTransition
				slots={{ backdrop: null }}
				onClose={()=>setIsCombinedChartClicked(false)}
				sx={{bgcolor:"#37415180",left:"292px",top:"88px"}}
				hideBackdrop
			>
				<Box>
					<Stack sx={{alignItems:"end",pr:"36px",pt:"24px",cursor:"pointer"}}>
						<Close sx={{color:"#FFFFFF"}} onClick={()=>setIsCombinedChartClicked(false)}/>
					</Stack>
					<Stack sx={{...modal_style,width:isDesktopLg?"700px":"460px",backgroundColor:"#374151"}}>
						<Typography sx={{color:"#FFFFFF",fontFamily:"Poppins",fontSize:"20px",fontWeight:700,lineHeight:"28px"}}>{message.response.labels[0]} & {message.response.labels[1]}</Typography>
						{/* <Box className="chart-container" sx={{textAlign:"-webkit-center"}} > */}
							<Radar data={combinedData} options={CombinedRadarchartoption1} />
						{/* </Box> */}
					</Stack>
				</Box>
		</Modal>:null}



		{/* <div
			className={`chat-message ${
				(message.user === "ai" && "chatgpt") ||
				(message.user === "user" && "customer")
			}`}
		>
			<div className="chat-message-center">
				<div>
					<div
						className={`avatar ${
							(message.user === "ai" && "chatgpt") ||
							(message.user === "user" && "customer")
						}`}
					>
						{message.user === "ai" && (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								strokeWidth={1.5}
								viewBox="0 0 40 40"
							>
								<path
									fill="currentColor"
									d="M37.532 16.87a9.963 9.963 0 0 0-.856-8.184 10.078 10.078 0 0 0-10.855-4.835A9.964 9.964 0 0 0 18.306.5a10.079 10.079 0 0 0-9.614 6.977 9.967 9.967 0 0 0-6.664 4.834 10.08 10.08 0 0 0 1.24 11.817 9.965 9.965 0 0 0 .856 8.185 10.079 10.079 0 0 0 10.855 4.835 9.965 9.965 0 0 0 7.516 3.35 10.078 10.078 0 0 0 9.617-6.981 9.967 9.967 0 0 0 6.663-4.834 10.079 10.079 0 0 0-1.243-11.813ZM22.498 37.886a7.474 7.474 0 0 1-4.799-1.735c.061-.033.168-.091.237-.134l7.964-4.6a1.294 1.294 0 0 0 .655-1.134V19.054l3.366 1.944a.12.12 0 0 1 .066.092v9.299a7.505 7.505 0 0 1-7.49 7.496ZM6.392 31.006a7.471 7.471 0 0 1-.894-5.023c.06.036.162.099.237.141l7.964 4.6a1.297 1.297 0 0 0 1.308 0l9.724-5.614v3.888a.12.12 0 0 1-.048.103l-8.051 4.649a7.504 7.504 0 0 1-10.24-2.744ZM4.297 13.62A7.469 7.469 0 0 1 8.2 10.333c0 .068-.004.19-.004.274v9.201a1.294 1.294 0 0 0 .654 1.132l9.723 5.614-3.366 1.944a.12.12 0 0 1-.114.01L7.04 23.856a7.504 7.504 0 0 1-2.743-10.237Zm27.658 6.437-9.724-5.615 3.367-1.943a.121.121 0 0 1 .113-.01l8.052 4.648a7.498 7.498 0 0 1-1.158 13.528v-9.476a1.293 1.293 0 0 0-.65-1.132Zm3.35-5.043c-.059-.037-.162-.099-.236-.141l-7.965-4.6a1.298 1.298 0 0 0-1.308 0l-9.723 5.614v-3.888a.12.12 0 0 1 .048-.103l8.05-4.645a7.497 7.497 0 0 1 11.135 7.763Zm-21.063 6.929-3.367-1.944a.12.12 0 0 1-.065-.092v-9.299a7.497 7.497 0 0 1 12.293-5.756 6.94 6.94 0 0 0-.236.134l-7.965 4.6a1.294 1.294 0 0 0-.654 1.132l-.006 11.225Zm1.829-3.943 4.33-2.501 4.332 2.5v5l-4.331 2.5-4.331-2.5V18Z"
								/>
							</svg>
						)}
					</div>
				</div>
				<div className="message">
					<div>{message.message}</div>
					
					{message.response.viz === "pieChart" && (
						<>
							<Box className="chart-container" sx={{width:"400px",textAlign:"-webkit-center"}} >
								<Pie data={data} option={chartoption} />
							</Box>
						</>
					)}
					{message.response.viz === "barChart" && (
						<>
							<Box className="chart-container" sx={{width:"800px",textAlign:"-webkit-center"}} >
								<Bar data={data} option={chartoption} />
							</Box>
						</>
					)}
					{message.response.viz === "doughnutChart" && (
						<>
							<Box className="chart-container" sx={{width:"500px",textAlign:"-webkit-center"}} >
								<Doughnut data={data} option={chartoption} />
							</Box>
						</>
					)}
					{message.response.viz === "lineChart" && (
						<>
							<Box className="chart-container" sx={{width:"800px",textAlign:"-webkit-center"}} >
								<Line data={data} option={chartoption} />
							</Box>
						</>
					)}
					{message.response.viz === "polarareaChart" && (
						<>
							<Box className="chart-container" sx={{width:"500px",textAlign:"-webkit-center"}} >
								<PolarArea data={data} option={chartoption} />
							</Box>
						</>
					)}
					{message.response.viz === "radarChart" && (
						<>
							<Box className="chart-container" sx={{width:"500px",textAlign:"-webkit-center"}} >
								<Radar data={data} option={chartoption} />
							</Box>
						</>
					)}
					{message.response.viz === "scatterChart" && (
						<>
							<Box className="chart-container" sx={{width:"500px",textAlign:"-webkit-center"}} >
								<Scatter data={data} option={chartoption} />
							</Box>
						</>
					)}
					{message.response.viz === "bubbleChart" && (
						<>
							<Box className="chart-container" sx={{width:"500px",textAlign:"-webkit-center"}} >
								<Bubble data={data} option={chartoption} />
							</Box>
						</>
					)}
					
				</div>
				<div ref={bottomRef} />
			</div>
		</div> */}
		</Grid>
	);
});

export default function DNA() {
	const API_BASE = "https://demobe.nlugpt.io/copilot/";
	// const API_BASE = "http://localhost:8000/copilot/";
	const [question, setQuestion] = useState("");
	const { REACT_APP_NLU_IL_URL } = process.env;
	const [chatLog, setChatLog] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);
	const [questionView, setQuestionView] = useState("DA");
	const [fileType, setFileType] = useState(null);
	const [filename, setfileName] = useState(null);
	const [isLoading, setLoading] = useState(false);
	const [history,setHistory]=useState([])
	const messagesEndRef = useRef(null)
	const [api_usage,setApi_usage]=useState(0)
	// const [restrictionOpen,setRestrictionOpen] = useState(false)
	const [CombinedChartModalopen,setCombinedChartModalopen] = useState(false)
	const isMobile = useMediaQuery({ maxWidth: 600 }); // Adjust the breakpoint as needed
	const isTablet = useMediaQuery({ query: '(min-width: 600px) and (max-width: 900px)' });
	const isLaptop = useMediaQuery({ query: '(min-width: 900px)' });
	const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });
	const isDesktopLg = useMediaQuery({ query: '(min-width: 1536px)' });
	const [isRadioBtnClicked,setIsRadioBtnClicked] = useState("DA")
	const [isSendBtnClicked,setIsSendBtnClicked] = useState(false)

	const modal_style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 370,
		// bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
		backgroundColor: '#141313'
	};

	// useEffect(() => {
    //     // console.log("inside useEffect")
    //     const storedToken = localStorage.getItem('token');
    //     // console.log("coming",jwtDecode(storedToken).user_api_usage.dna)
    //     if (!storedToken) {
			
	// 		// console.log("inside else")
    //         // Redirect to login page
    //         navigate("/login");
    //     }
	// 	else{
	// 		setApi_usage(jwtDecode(storedToken).user_api_usage.dna)

	// 	}
    //   }, []);

	const navigate = useNavigate();
	const bottomRef = useRef(null);
	const victoryColors = [
		"#d66",
		"#d96",
		"#dd6",
		"#9d6",
		"#6d6",
		"#6d9",
		"#6dd",
		"#69d",
		"#66d",
		"#96d",
		"#d6d",
		"#d69",
	];
	const victoryTheme = {
		bar: {
			style: {
				data: {
					fill: "#88d",
				},
				labels: {
					padding: 3,
					fontSize: 12,
					fill: "white",
				},
			},
		},
		pie: {
			colorScale: victoryColors,
			width: 350,
			height: 380,
			style: {
				labels: {
					padding: 20,
					fill: "white",
				},
			},
		},
	};

	const [isMAChecked,setIsMAChecked] = useState(false)

	useEffect(() => {
		// 👇️ scroll to bottom every time messages change
		bottomRef.current?.scrollIntoView({ behavior: "smooth" });
	}, [chatLog]);

	// const storedToken = localStorage.getItem('token');
	// if (!storedToken) {
	// 	// Redirect to login page
	// 	setTimeout(() => navigate("/login"), 0);
	// }


	const handleFileChange = event => {
		setQuestionView("DA");
		openFileDialog()
	};

	const uploadCSV = async selectedFile => {
		console.log("upload csv file console")
		const formData = new FormData();
		formData.append("file_url", selectedFile);
		const extension = selectedFile.name.split(".").pop();
		const filename = selectedFile.name
		setfileName(filename)
		setFileType(extension);

		const response = await axios.post(
			"https://testil.nlugpt.io/wolverine/uploadcsv/",
			formData,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}
		);
		if (response.status === 201) {
			openFileDialog();
			
			// Handle the extracted data returned from the backend
		} else {
			throw new Error("File upload failed.");
		}
	};

	const loadFile = (val) => {
		setQuestion("")
		if(val == "DA"){
			setIsMAChecked(false)
			setHistory([])
			openFileDialog()
		}
		else{
			setIsMAChecked(!isMAChecked)
			// console.log("--coming--------")
			setQuestionView("MA")
			// setQuestion('')
			setChatLog([])
			setHistory([])
		}
	}
	// console.log("--------question------",question)

	const openFileDialog = async() => {
		// const api_usage_response = await axios.post(API_BASE + 'api_usage/',{"user_id":jwtDecode(storedToken).user_id,"type":"dna"})
		// if((api_usage_response.status === 200 && api_usage_response.data.count >= 0 )|| api_usage_response.data.user==7){

			setQuestionView("DA");
			setChatLog([]);
		// 	// document.getElementById("dna-input").click();
		// }
		// else if (api_usage_response.status === 200 && api_usage_response.data.count<0){
		// 	// setRestrictionOpen(true)
		// 	setLoading(false);
		// }
		// else{
		// 	setLoading(false);
		// }
	};

	function handleSubmit(event) {

		setIsSendBtnClicked(true)
		validateInputAndCallAPI();
		event.preventDefault(); // 👈️ prevent page refresh
	}
	// const RestrictionhandleClose = () => setRestrictionOpen(false);

	
	async function validateInputAndCallAPI() {
		setLoading(true)
		setTimeout(() => {
	
	setLoading(false)
		if (question === "") {
			alert("Please provide Question before calling this API");
			return;
		} else {
			let chatLogNew = [...chatLog, { user: "user", message: question }];
			setChatLog(chatLogNew);
				//   setLoading(true);
				//   let response = await axios.post(
				// 	  "https://testil.nlugpt.io/wolverine/intelligenceLayer/",
				// 	  {
				// 		  userIntent: question,
				// 		  file_type: fileType,
				// 		  file_name:filename
				// 	  }
				//   );
				const response = {}
				if(question ==="Plot a bar chart between pregnancies and glucose based on the outcome."){
					response.data={
					"barChart": {
						"Pregnancies": {
							"0": 3.298,
							"1": 4.865672
						},
						"Glucose": {
							"0": 109.98,
							"1": 141.257463
						}
					}
				}}
				else if(question ==="What is the average skin thickness for 13, 2, 4 times pregnant women with high BMI?"){
					response.data={
						"message":"The average skin thickness for 13, 2, 4 times pregnant women with high BMI is approximately 22.29"
				}}
				else if(question ==="What is the average glucose, BMI, blood pressure and skin thickness level of women who went through pregnancies 7 times"){
					response.data={
						"barChart": {
							"Glucose": {
								"0": 136.444444
							},
							"BMI": {
								"0": 32.631111
							},
							"BloodPressure": {
								"0": 70.777778
							},
							"SkinThickness": {
								"0": 20.288889
							}
						}
				}}
				else if(question ==="What is the percentage difference in BMI for pregnancies below 10 and above 10 compare in doughnut chart"){
					response.data={
						"doughnutChart": {
							"Pregnancies below 10": 31.86,
							"Pregnancies above 10": 35.69
						}
				}}
				else if(question ==="What is the range of pregnancies, skin thickness, glucose, blood pressure, and BMI for the outcome to be 1 show it in bar chart"){
					response.data={
						"barChart": {
							"Pregnancies": {
								"min": 0,
								"max": 17
							},
							"SkinThickness": {
								"min": 0,
								"max": 99
							},
							"Glucose": {
								"min": 0,
								"max": 199
							},
							"BloodPressure": {
								"min": 0,
								"max": 114
							},
							"BMI": {
								"min": 0.0,
								"max": 67.1
							}
						
					}
				}}
				else if(question === "Identify potential customers who might switch to Westside"){
					response.data ={
						"radarChart":{
							"Westside": {
								"Automobiles": 75,
								"Airlines": 14,
								"Cafes": 96,
								"Hotels": 80,
								"Jewellery": 123,
								"Watches": 94,
								"Appliances": 77,
								"Beauty Care": 135,
								"Personal Care": 138,
								"Entertainment": 81,
								"E-commerce": 117,
								"Finance": 20,
								"Wellness": 41,
								"Nourishment": 105
							},
							"Fabindia": {
								"Automobiles": 25,
								"Airlines": 12,
								"Cafes": 32,
								"Hotels": 38,
								"Jewellery": 35,
								"Watches": 27,
								"Appliances": 19,
								"Beauty Care": 39,
								"Personal Care": 41,
								"Entertainment": 24,
								"E-commerce": 43,
								"Finance": 7,
								"Wellness": 10,
								"Nourishment": 35
							}
						}
					}
				}
				else if(question === "who will be the potential switchers for Max"){
					response.data ={
						"radarChart":{
							"Max": {
								"Automobiles": 39,
								"Airlines": 18,
								"Cafes": 67,
								"Hotels": 63,
								"Jewellery": 56,
								"Watches": 46,
								"Appliances": 45,
								"Beauty Care": 64,
								"Personal Care": 58,
								"Entertainment": 56,
								"E-commerce": 79,
								"Finance": 15,
								"Wellness": 35,
								"Nourishment": 84
							},
							"Fabindia": {
								"Automobiles": 25,
								"Airlines": 12,
								"Cafes": 32,
								"Hotels": 38,
								"Jewellery": 35,
								"Watches": 27,
								"Appliances": 19,
								"Beauty Care": 39,
								"Personal Care": 41,
								"Entertainment": 24,
								"E-commerce": 43,
								"Finance": 7,
								"Wellness": 10,
								"Nourishment": 35
							}
						}
					}
				}
				
				
				  setHistory((previousvalue)=>{
					  const value=[...previousvalue,question]
					  return value
				  })
				//   setQuestion("");
				  if (response.data) {
					  if (response.data.message) {
						  const data = response.data.message;
						  let newChatLog = [
							  ...chatLog,
							  { user: "user", message: question, response: { user: "ai", message: `${data}` } }
							  
						  ];
						  setChatLog(newChatLog);
					  } else {
						  const graph = response.data.pieChart || response.data.barChart || response.data.doughnutChart || response.data.lineChart
										  || response.data.polarareaChart || response.data.radarChart || response.data.scatterChart || response.data.bubbleChart;
						
						  const { dataValues1, stacked } = processGraph(graph);
												//   console.log("================================ stacked", stacked);
						//   console.log("================================ datavales", dataValues1);
						  let newChatLog = [
							  ...chatLog,
							  { user: "user", message: question, response:
								  {
									  user: "ai",
									  message: "Here is your visualization:",
									  viz: Object.keys(response.data)[0],
									  data: Object.entries(graph).map(([x, y]) => ({
										  x: `${x} (${y})`,
										  y,
									  })),
									  dataValues1,
									  stacked,
									  dataValues: Object.values(graph),
									  labels: Object.keys(graph)
								  }
							  }
							  
						  ];
						  						  setChatLog(newChatLog);
					  }
					  setLoading(false);
					  return true;
			}
			
		}
	}, 2200);
	}

	function processGraph(graph) {
		// console.log("process graph function----------------------------------------------------------------")
		
		let stacked= false;

		const dataValues1 = {
		//   label: [],
		};
	  
		if (typeof Object.values(graph)[0] === 'object') {
		  const subKeys = Object.keys(graph[Object.keys(graph)[0]]);
		  subKeys.forEach(subKey => {
			// dataValues1.label.push(subKey);
			dataValues1[subKey.toLowerCase()] = Object.values(graph).map(item => item[subKey]);
		  });
		  stacked = true
		} else {
			// dataValues1.label = Object.keys(graph);
		  Object.keys(graph).forEach(key => {
			dataValues1[key] = [graph[key]];
		  });
		  stacked = false
		}
		// console.log("data", dataValues1);
		return { dataValues1, stacked };
	  }


useEffect(()=>{

	if(messagesEndRef.current){
		messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
	}
	
},[isLoading])

const scrollQuestion=(index)=>{
	let id="section_"+index
	const targetElement = document.getElementById(id);
	// console.log(targetElement)
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }

}

const handleKeyPress = (e) => {
	if (e.key === 'Enter') {
	  handleSubmit(e); 
	}
  };

const handleRadioBtnChange = (e)=>{
	setIsRadioBtnClicked(e.target.value)
	setIsSendBtnClicked(false)
}


	return (
		<div className="App">
			<Header />
			<aside className="sidemenu">
				<div style={{ display: "none" }}>
					<input
						type="file"
						onChange={handleFileChange}
						// accept="text/csv, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						id="dna-input"
					/>
				</div>
				{/* {!questionView &&<h4>Try our sample data</h4>} */}
				<RadioGroup value={isRadioBtnClicked} onChange={(e)=>handleRadioBtnChange(e)}>
					<FormControlLabel sx={{mb:"1rem"}} value="DA" name="DA" control={<Radio sx={{color:"#CBD5E1","&.Mui-checked":{color:"#EC4899"}}}/>} label="Data Analysis " onClick={(e)=>loadFile("DA")}/>
					<div /* className="sidemenu-button" */ style={{display:"flex",alignItems:"center",cursor:"pointer",marginBottom:"24px"}}>
						<img style={{width:"45px",height:"45px"}} src={uploadimg} alt="upload"/>
						Diabetes.csv
					</div>
					<FormControlLabel sx={{mb:"1rem"}} value="MA" name="MA" control={<Radio onClick={(e)=>loadFile("MA")} sx={{color:"#CBD5E1","&.Mui-checked":{color:"#EC4899"}}}/>} label="Market Analysis"/>
					<div /* className="sidemenu-button" */ style={{display:"flex",alignItems:"center",cursor:"pointer",marginBottom:"24px"}}>
						<img style={{width:"45px",height:"45px"}} src={uploadimg} alt="upload"/>
						Clothing.xlsx
					</div>
				</RadioGroup>
				{
					questionView === "DA" ?
					<div>
						<form onSubmit={handleSubmit}>
							<div style={{marginBottom:"24px"}}>
								{/* <input
									id="userInt"
									className="chat-input-textarea"
									rows="3"
									placeholder="Enter your question"
									value={question}
									
									// onChange={e => {
									// 	setQuestion(e.target.value);
									// }}
									></input> */}
									<select className="chat-input-textarea" value={question} onKeyDown={(e)=>handleKeyPress(e)}  onChange={(e)=>setQuestion(e.target.value)}>
										<option className="option" value="">Select any questions</option>
										<option value="Plot a bar chart between pregnancies and glucose based on the outcome."><div className="option"> Plot a bar chart between pregnancies and glucose based on the outcome.</div> </option>
										<option value="What is the average skin thickness for 13, 2, 4 times pregnant women with high BMI?">What is the average skin thickness for 13, 2, 4 times pregnant women with high BMI?</option>
										<option value="What is the average glucose, BMI, blood pressure and skin thickness level of women who went through pregnancies 7 times">What is the average glucose, BMI, blood pressure and skin thickness level of women who went through pregnancies 7 times</option>
										<option value="What is the percentage difference in BMI for pregnancies below 10 and above 10 compare in doughnut chart">What is the percentage difference in BMI for pregnancies below 10 and above 10 compare in doughnut chart</option>
										<option value="What is the range of pregnancies, skin thickness, glucose, blood pressure, and BMI for the outcome to be 1 show it in bar chart">What is the range of pregnancies, skin thickness, glucose, blood pressure, and BMI for the outcome to be 1 show it in bar chart</option>
									</select>
							

							</div>
							<div>
							<button className="btn-form-submit" style={{width:"100%"}} onClick={()=>handleSubmit}>Send</button>
							</div>
						</form>
					</div>
					:
					questionView === "MA" ?
					<div>
						<form onSubmit={(e)=>handleSubmit(e)}>
							<div style={{marginBottom:"24px"}}>
									<select className="chat-input-textarea" value={question} onKeyDown={(e)=>handleKeyPress(e)}  onChange={(e)=>setQuestion(e.target.value)}>
										<option className="option" value="">Select any questions</option>
										{/* <option><div className="option"> Plot a bar chart between pregnancies and glucose based on the outcome.</div> </option> */}
										<option value="Identify potential customers who might switch to Westside">Identify potential customers who might switch to Westside.</option>
										<option value="who will be the potential switchers for Max">who will be the potential switchers for Max</option>
										{/* <option>What is the percentage difference in BMI for pregnancies below 10 and above 10 compare in doughnut chart</option>
										<option>What is the range of pregnancies, skin thickness, glucose, blood pressure, and BMI for the outcome to be 1 show it in bar chart</option> */}
									</select>
							

							</div>
							<div>
							<button className="btn-form-submit" style={{width:"100%"}} onClick={()=>handleSubmit}>Send</button>
							</div>
						</form>
					</div>
					:null
					// </form>
				}
				{
					history.length>0 ? <div className="history">
						<div className="history_heading">
					<p>History</p></div>
					<div className="history-container">
                        {history.map((value,index)=>{
						return (
							<div className="history_topics" onClick={()=>scrollQuestion(index)}><p className="history_text" >
								{value}
							</p>

							</div>
						)
					} 
						
					)}
					</div>
				</div>:null
				}				
			</aside>
			<section className="chatbox" style={{display:isRadioBtnClicked && !isSendBtnClicked?"flex":"block",justifyContent:"center",alignItems:"center"}}>
				{questionView && isSendBtnClicked ? (
					<div className="chat-log" ref={messagesEndRef}>
						{isLoading &&
							(
							<div
								style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%',
								backgroundColor: '#343541',
								width:"100%"
								}}
							>
								<div className="loading-container">
								{/* <div className="dot-animation">
									<span></span>
									<span></span>
									<span></span>
								</div> */}
								<HoopSpinner size={30} color="#fdb813" />
								</div>
							</div>
							)
						}
						
						{isLoading ? null : chatLog.map((message, index) => (
							<ChatMessage index={index} message={message} ma={isMAChecked}/>
						)) }
											</div>
				) 
				// :isRadioBtnClicked === "DA"?  (
				// 	<div className="csv-upload-first">
				// 		<h1>Data Analysis</h1>
				// 		<p>
				// 			Upload a file on the left to ask the AI questions.
				// 		</p>
				// 		<div>
				// 			<div>
				// 				<h2>Current features</h2>
				// 				<p>
				// 					Upload Excel, CSV, or PDF files.<br />
				// 					Ask the AI questions about the data and get answers.<br />
				// 					The AI can visualize data as pie charts or bar charts.
				// 				</p>
				// 			</div>
				// 			<div>
				// 				<h2>Tips & Tricks</h2>
				// 				<p>
				// 					When using Excel or CSV, try to ask for visualizations of<br />
				// 					a whole column of data instead of individual pieces of data<br />
				// 					contained in the column.
				// 				</p>
				// 			</div>
				// 		</div>
				// 	</div>
				// )
				:
				<Stack sx={{width:"613px",gap:"28px",alignItems:"flex-start",justifyContent:"center"}}>
					<Stack direction="row" sx={{gap:"48px",alignItems:"center",justifyContent:"flex-start"}}>
						<Box component="img" src={Analytics_svg} sx={{width:"64px",height:"64px"}}/>
						<Stack sx={{gap:"8px",alignItems:"flex-start"}}>
							<Typography sx={{fontFamily:"Poppins",fontSize:"24px",fontWeight:700,lineHeight:"32px"}}>{isRadioBtnClicked === "DA"?"Data":"Market"}  Analysis</Typography>
							<Typography sx={{fontFamily:"Poppins",fontSize:"18px",fontWeight:400,lineHeight:"28px"}}>Upload a file on the left to ask the AI questions.</Typography>
						</Stack>
					</Stack>
					<Stack direction="row" sx={{gap:"48px",alignItems:"flex-start",justifyContent:"flex-start"}}>
						<Box component="img" src={isRadioBtnClicked === "DA"?Data_visualization_svg:Radar_svg} sx={{width:"64px",height:"64px"}}/>
						<Stack sx={{gap:"8px",alignItems:"flex-start"}}>
							<Typography sx={{fontFamily:"Poppins",fontSize:"24px",fontWeight:700,lineHeight:"32px"}}>{isRadioBtnClicked === "DA"?"Current Features":"New Feature"}</Typography>
							{isRadioBtnClicked === "DA"?
								<Typography sx={{width:"500px",fontFamily:"Poppins",fontSize:"18px",fontWeight:400,lineHeight:"28px",textAlign:"left"}}>Upload Excel, CSV, or PDF files. Ask the AI questions about the data and get answers. The AI can visualize data as pie charts or bar charts.</Typography>
							:
							<Typography sx={{width:"500px",fontFamily:"Poppins",fontSize:"18px",fontWeight:400,lineHeight:"28px",textAlign:"left"}}>A market analysis that has separate charts for every data point feels cluttered. With a radar chart, all your data is gathered into one web, revealing strengths and weaknesses simultaneously. </Typography>
							}
							</Stack>
					</Stack>
					<Stack direction="row" sx={{gap:"48px",alignItems:"flex-start",justifyContent:"flex-start"}}>
						<Box component="img" src={isRadioBtnClicked === "DA"?Idea_svg:Privilege_svg} sx={{width:"64px",height:"64px"}}/>
						<Stack sx={{gap:"8px",alignItems:"flex-start"}}>
							<Typography sx={{fontFamily:"Poppins",fontSize:"24px",fontWeight:700,lineHeight:"32px"}}>{isRadioBtnClicked === "DA"?"Tips & Tricks":"Benefits"}</Typography>
							{
								isRadioBtnClicked === "DA"?
								<Typography sx={{width:"500px",fontFamily:"Poppins",fontSize:"18px",fontWeight:400,lineHeight:"28px",textAlign:"left"}}>When using Excel or CSV, try to ask for visualizations of a whole column of data instead of individual pieces of data contained in the column.</Typography>
								:
								<Typography sx={{width:"500px",fontFamily:"Poppins",fontSize:"18px",fontWeight:400,lineHeight:"28px",textAlign:"left"}}>It helps you identify trends, compare competitors, and make smarter decisions.</Typography>
							}
						</Stack>
					</Stack>

				</Stack>
			}
			</section>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				// open={restrictionOpen}
				// onClose={RestrictionhandleClose}
				closeAfterTransition
			>
				<Box sx={{...modal_style,textAlign:"center"}}>
					<Typography sx={{ color: "white", mb:2 }} variant='h5'>
					Out of demo credits? Contact us for more.
					</Typography>
					<Button variant='contained' sx={{ bgcolor: "#fdb813", color: "#33336f", "&:hover": { bgcolor: "#fdb813", color: "#33336f" } }}>

					<Box component="a" href="https://arivu-iq.com/#ourContact" target="_blank" sx={{textDecoration:"none"}} >Contact US</Box>
					</Button>
				</Box>
			</Modal>
		</div>
	);
}
