import React, { useState } from "react";
import { Box, Button, Paper, Typography,TextField, InputBase, } from "@mui/material"
import { motion } from 'framer-motion';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import LoopIcon from '@mui/icons-material/Loop';
import "../styles/title.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const TitleDisplay=({heading})=>{
    const { title, setTitle, deleteTitle, addTitle, titleChange, titleRegeneration, handleOpen, createStory, error } = heading
    title.forEach((item, ind) => {
        item.id = ind + 1 + "";
    });

    const hanndleDragDrop = (results) => {
        
        const { source, destination, type } = results;
        if (!destination) return;
        if (source.draggableId === destination.droppableId && source.index === destination.index)
            return;

        const reOrderedTitle = [...title]

        const sourceIndex = source.index;
        const destinationIndex = destination.index;

        const [removedTitle] = reOrderedTitle.splice(sourceIndex, 1)
        reOrderedTitle.splice(destinationIndex, 0, removedTitle)

        setTitle(reOrderedTitle)
    }

    return (
        <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
                <Box sx={{display:"flex",alignItems:"center",justifyContent:"center",height:"100vh"}}>
                    <DragDropContext
                        onDragEnd={hanndleDragDrop}
                    >
                    <Paper  sx={{bgcolor:"#1f2937",padding:"12px",display:"flex",flexDirection:"column",gap:"12px",borderRadius:"8px",marginTop:"64px" }}>
                        <Box onClick={handleOpen} sx={{display:"flex",alignItems:"center",justifyContent:"flex-start","&:hover":{cursor:"pointer"}}}><ArrowBackIcon sx={{color:"#fff",fontSize:"18px",alignSelf:"self-end",padding:"4px"}}/><Typography sx={{color:"#fff",fontSize:"14px",fontWeight:"500px",lineHeight:"20px"}}>Go back to topic selection</Typography></Box>
                        
                    <Box sx={{bgcolor:"#111827",padding:"12px",borderRadius:"8px"}}>
                        
                        <Typography sx={{color:"#9CA3AF",fontSize:"12px",fontWeight:"600",lineHeight:"16px"}}>Topic Looking for: </Typography> 
                        <Typography sx={{color:"#fff",fontWeight:"600",lineHeight:"24px"}}>{title.length} Scene of Innovative Generative AI Business Ideas</Typography>
                            
                        </Box>
                        {/* <Droppable droppableId="titleBox" type="group">
                        {(provided) => (
                            <Box sx={{display:"flex",flexDirection:"column",gap:"12px",position:"static"}} ref={provided.innerRef} {...provided.droppableProps} > */}

                                {title.map(({ id, title }, ind) => {
                                    return (
                                        // <Draggable
                                        //     key={id}
                                        //     draggableId={id}
                                        //     index={ind}
                                        //     bounds="parent"
                                        // >
                                        //     {(provided) => (
                                                

                                                    <Box sx={{ bgcolor: "#111827", padding: "4px 12px", display: "flex", alignItems: "center", justifyContent: "space-between", borderRadius: "8px" }}>

                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                            <Box sx={{ display: "flex", alignItems: "center" }}><DragIndicatorIcon sx={{ color: "#fff", fontSize: "18px", padding: "4px" }} /></Box>
                                                            <Box sx={{ marginLeft: "12px" }}>
                                                                <Typography sx={{ fontSize: "12px", fontWeight: "600", lineHeight: "16px", color: "#9CA3AF" }}>Scene{ind + 1}</Typography>
                                                                {/* <Typography sx={{color:"#fff",fontSize:"14px",lineHeight:"20px",fontWeight:500}}>{item.title}</Typography>  */}
                                                                <InputBase component={TextField} type='text' value={title} sx={{ color: "#fff", width: { sm: "300px", xs: "200px" } }} multiline={true} onChange={(e) => titleChange(e.target.value, ind)} />
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "4px" }}>
                                                            <AddIcon sx={{ color: "#fff", fontSize: "18px", alignSelf: "self-end", padding: "4px", "&:hover": { cursor: "pointer" } }} onClick={() => addTitle(ind)} />
                                                            <DeleteIcon sx={{ color: "#fff", fontSize: "18px", alignSelf: "self-end", padding: "4px", "&:hover": { cursor: "pointer" } }} onClick={() => deleteTitle(ind)} />
                                                        </Box>
                                                    </Box>
                                        //     )}
                                        // </Draggable>
                                    )
                                })}
                                {/* {provided.placeholder}
                            </Box>  
                        )}
                    </Droppable> */}
                    {error?  <Box sx={{display:"flex",justifyContent:"center",padding:"12px "}}><Typography style={{ color: "red" }}>{error}</Typography></Box>:null}
    
                    <Box sx={{display:"flex",justifyContent:"space-between",padding:"12px "}}>
                        <Box onClick={titleRegeneration} sx={{display:"flex",justifyContent:"center",alignItems:"center",color:"#64748B",gap:"8px","&:hover":{cursor:"pointer"}}}>
                            <LoopIcon sx={{padding:"4px",display:"flex",justifyContent:"center"}}/>
                            <Typography sx={{fontSize:"14px",color:"#64748B",fontWeight:"400",lineHeight:"20px"}}>Regenerate</Typography>
                        </Box>
                        <Box component={Button} onClick={createStory} sx={{marginRight:"12px",bgcolor:"#fdb813",color:"#33336f",padding:"0px 20px","&:hover":{bgcolor:"#fdb813",color:"#33336f"}}} variant="contained">
                            Continue
                        </Box>
                    

                    </Box>

                    </Paper>
                    </DragDropContext>
                    
                 
                </Box>
                </motion.div>


    )
      
    





}
export default TitleDisplay
